// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Eq_Generic = require("../Data.Eq.Generic/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ord_Generic = require("../Data.Ord.Generic/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var TargetMuscle = (function () {
    function TargetMuscle() {

    };
    TargetMuscle.value = new TargetMuscle();
    return TargetMuscle;
})();
var SynergistMuscle = (function () {
    function SynergistMuscle() {

    };
    SynergistMuscle.value = new SynergistMuscle();
    return SynergistMuscle;
})();
var StabilizerMuscle = (function () {
    function StabilizerMuscle() {

    };
    StabilizerMuscle.value = new StabilizerMuscle();
    return StabilizerMuscle;
})();
var VH = (function () {
    function VH() {

    };
    VH.value = new VH();
    return VH;
})();
var H = (function () {
    function H() {

    };
    H.value = new H();
    return H;
})();
var M = (function () {
    function M() {

    };
    M.value = new M();
    return M;
})();
var L = (function () {
    function L() {

    };
    L.value = new L();
    return L;
})();
var VL = (function () {
    function VL() {

    };
    VL.value = new VL();
    return VL;
})();
var ScheduleWorkout = (function () {
    function ScheduleWorkout(value0) {
        this.value0 = value0;
    };
    ScheduleWorkout.create = function (value0) {
        return new ScheduleWorkout(value0);
    };
    return ScheduleWorkout;
})();
var ScheduleRest = (function () {
    function ScheduleRest() {

    };
    ScheduleRest.value = new ScheduleRest();
    return ScheduleRest;
})();
var Compound = (function () {
    function Compound() {

    };
    Compound.value = new Compound();
    return Compound;
})();
var Isolation = (function () {
    function Isolation() {

    };
    Isolation.value = new Isolation();
    return Isolation;
})();
var NoPeriodization = (function () {
    function NoPeriodization() {

    };
    NoPeriodization.value = new NoPeriodization();
    return NoPeriodization;
})();
var DailyUndulating = (function () {
    function DailyUndulating(value0) {
        this.value0 = value0;
    };
    DailyUndulating.create = function (value0) {
        return new DailyUndulating(value0);
    };
    return DailyUndulating;
})();
var WorkoutDay = (function () {
    function WorkoutDay(value0) {
        this.value0 = value0;
    };
    WorkoutDay.create = function (value0) {
        return new WorkoutDay(value0);
    };
    return WorkoutDay;
})();
var RestDay = (function () {
    function RestDay() {

    };
    RestDay.value = new RestDay();
    return RestDay;
})();
var writeForeignIntensity = new Simple_JSON.WriteForeign(function (v) {
    if (v instanceof VH) {
        return Foreign.unsafeToForeign("VH");
    };
    if (v instanceof H) {
        return Foreign.unsafeToForeign("H");
    };
    if (v instanceof M) {
        return Foreign.unsafeToForeign("M");
    };
    if (v instanceof L) {
        return Foreign.unsafeToForeign("L");
    };
    if (v instanceof VL) {
        return Foreign.unsafeToForeign("VL");
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 173, column 1 - line 178, column 38): " + [ v.constructor.name ]);
});
var writeForeignExerciseCategory = new Simple_JSON.WriteForeign(function (v) {
    if (v instanceof Compound) {
        return Foreign.unsafeToForeign("compound");
    };
    if (v instanceof Isolation) {
        return Foreign.unsafeToForeign("isolation");
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 127, column 1 - line 129, column 52): " + [ v.constructor.name ]);
});
var workoutDay = function (groups) {
    return new ScheduleWorkout({
        groups: groups
    });
};
var readForeignIntensity = new Simple_JSON.ReadForeign(function (val) {
    var err = (function () {
        var $53 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity));
        var $54 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
        return function ($55) {
            return $53($54(Foreign.ForeignError.create($55)));
        };
    })();
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Simple_JSON.readImpl(Simple_JSON.readString)(val))(function (str) {
        if (str === "VH") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(VH.value);
        };
        if (str === "H") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(H.value);
        };
        if (str === "M") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(M.value);
        };
        if (str === "L") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(L.value);
        };
        if (str === "VL") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(VL.value);
        };
        return err("Could not parse '" + (str + "' as intensity"));
    });
});
var readForeignExerciseCategory = new Simple_JSON.ReadForeign(function (val) {
    var err = (function () {
        var $56 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity));
        var $57 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
        return function ($58) {
            return $56($57(Foreign.ForeignError.create($58)));
        };
    })();
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Simple_JSON.readImpl(Simple_JSON.readString)(val))(function (str) {
        if (str === "compound") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Compound.value);
        };
        if (str === "isolation") {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Compound.value);
        };
        return err("Could not parse '" + (str + "' as exercise category"));
    });
});
var genericScheduleDay = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ScheduleWorkout) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof ScheduleRest) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 39, column 1 - line 39, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new ScheduleWorkout(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return ScheduleRest.value;
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 39, column 1 - line 39, column 60): " + [ x.constructor.name ]);
});
var showScheduleDay = new Data_Show.Show(Data_Show_Generic.genericShow(genericScheduleDay)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "groups";
}))(Data_Show.showRecordFieldsNil)(Data_Set.showSet(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "ScheduleWorkout";
})))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ScheduleRest";
})))));
var genericPeriodization = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof NoPeriodization) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof DailyUndulating) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 59, column 1 - line 59, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return NoPeriodization.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new DailyUndulating(x.value0);
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 59, column 1 - line 59, column 64): " + [ x.constructor.name ]);
});
var genericMuscleRole = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TargetMuscle) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof SynergistMuscle) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof StabilizerMuscle) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 110, column 1 - line 110, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return TargetMuscle.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return SynergistMuscle.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return StabilizerMuscle.value;
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 110, column 1 - line 110, column 58): " + [ x.constructor.name ]);
});
var showMuscleRole = new Data_Show.Show(Data_Show_Generic.genericShow(genericMuscleRole)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TargetMuscle";
})))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SynergistMuscle";
})))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "StabilizerMuscle";
}))))));
var genericIntensity = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof VH) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof H) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof M) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof L) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof VL) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 166, column 1 - line 166, column 56): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return VH.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return H.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return M.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return L.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return VL.value;
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 166, column 1 - line 166, column 56): " + [ x.constructor.name ]);
});
var showIntensity = new Data_Show.Show(Data_Show_Generic.genericShow(genericIntensity)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VH";
})))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "H";
})))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "M";
})))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "L";
})))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VL";
}))))))));
var showPeriodization = new Data_Show.Show(Data_Show_Generic.genericShow(genericPeriodization)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "NoPeriodization";
})))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "schedule";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showIntensity)))))(new Data_Symbol.IsSymbol(function () {
    return "DailyUndulating";
})))));
var genericExerciseCategory = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Compound) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Isolation) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 120, column 1 - line 120, column 70): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Compound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Isolation.value;
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 120, column 1 - line 120, column 70): " + [ x.constructor.name ]);
});
var showExerciseCategory = new Data_Show.Show(Data_Show_Generic.genericShow(genericExerciseCategory)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Compound";
})))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Isolation";
})))));
var showDayPlan = new Data_Show.Show(function (v) {
    if (v instanceof RestDay) {
        return "RestDay";
    };
    if (v instanceof WorkoutDay) {
        return "WorkoutDay (" + (Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "exercises";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "exercise";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "group";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "repScheme";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))))))(Data_Show.showString))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "category";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "muscles";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "scheme";
        }))(Data_Show.showRecordFieldsNil)(Data_Map_Internal.showMap(showIntensity)(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt)))))))(Data_Show.showString))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "stabilizers";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "synergists";
        }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "target";
        }))(Data_Show.showRecordFieldsNil)(Data_Set.showSet(Data_Show.showString)))(Data_Set.showSet(Data_Show.showString)))(Data_Set.showSet(Data_Show.showString)))))(showExerciseCategory))))))))(v.value0) + ")");
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Types (line 73, column 1 - line 75, column 68): " + [ v.constructor.name ]);
});
var eqMuscleRole = new Data_Eq.Eq(Data_Eq_Generic.genericEq(genericMuscleRole)(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments)))));
var ordMuscleRole = new Data_Ord.Ord(function () {
    return eqMuscleRole;
}, Data_Ord_Generic.genericCompare(genericMuscleRole)(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments)))));
var eqIntensity = new Data_Eq.Eq(Data_Eq_Generic.genericEq(genericIntensity)(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments)))))));
var eqPeriodization = new Data_Eq.Eq(Data_Eq_Generic.genericEq(genericPeriodization)(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqArgument(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
    return "schedule";
}))(Data_Eq.eqArray(eqIntensity))))))));
var ordIntensity = new Data_Ord.Ord(function () {
    return eqIntensity;
}, Data_Ord_Generic.genericCompare(genericIntensity)(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments)))))));
var ordPeriodization = new Data_Ord.Ord(function () {
    return eqPeriodization;
}, Data_Ord_Generic.genericCompare(genericPeriodization)(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdArgument(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
    return "schedule";
}))(Data_Ord.ordArray(ordIntensity))))))));
var eqExerciseCategory = new Data_Eq.Eq(Data_Eq_Generic.genericEq(genericExerciseCategory)(Data_Eq_Generic.genericEqSum(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments))));
var ordExerciseCategory = new Data_Ord.Ord(function () {
    return eqExerciseCategory;
}, Data_Ord_Generic.genericCompare(genericExerciseCategory)(Data_Ord_Generic.genericOrdSum(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))(Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))));
var emptyWorkout = {
    exercises: [  ]
};
var emptyScheduleWorkoutDay = {
    groups: Data_Set.empty
};
var emptyMuscles = {
    target: Data_Set.empty,
    synergists: Data_Set.empty,
    stabilizers: Data_Set.empty
};
var defaultRepSchemes = Data_Map_Internal.fromFoldable(ordIntensity)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(VH.value, {
    sets: 5,
    reps: {
        min: 1,
        max: 3
    }
}), new Data_Tuple.Tuple(H.value, {
    sets: 4,
    reps: {
        min: 3,
        max: 4
    }
}), new Data_Tuple.Tuple(M.value, {
    sets: 1,
    reps: {
        min: 5,
        max: 8
    }
}), new Data_Tuple.Tuple(L.value, {
    sets: 1,
    reps: {
        min: 8,
        max: 12
    }
}), new Data_Tuple.Tuple(VL.value, {
    sets: 1,
    reps: {
        min: 12,
        max: 15
    }
}) ]);
var exercise = function (name) {
    return function (category) {
        return {
            name: name,
            scheme: defaultRepSchemes,
            muscles: emptyMuscles,
            category: category
        };
    };
};
var allIntensities = [ VH.value, H.value, M.value, L.value, VL.value ];
module.exports = {
    ScheduleWorkout: ScheduleWorkout,
    ScheduleRest: ScheduleRest,
    workoutDay: workoutDay,
    emptyScheduleWorkoutDay: emptyScheduleWorkoutDay,
    NoPeriodization: NoPeriodization,
    DailyUndulating: DailyUndulating,
    WorkoutDay: WorkoutDay,
    RestDay: RestDay,
    emptyWorkout: emptyWorkout,
    TargetMuscle: TargetMuscle,
    SynergistMuscle: SynergistMuscle,
    StabilizerMuscle: StabilizerMuscle,
    Compound: Compound,
    Isolation: Isolation,
    exercise: exercise,
    emptyMuscles: emptyMuscles,
    VH: VH,
    H: H,
    M: M,
    L: L,
    VL: VL,
    allIntensities: allIntensities,
    defaultRepSchemes: defaultRepSchemes,
    genericScheduleDay: genericScheduleDay,
    showScheduleDay: showScheduleDay,
    genericPeriodization: genericPeriodization,
    eqPeriodization: eqPeriodization,
    ordPeriodization: ordPeriodization,
    showPeriodization: showPeriodization,
    showDayPlan: showDayPlan,
    genericMuscleRole: genericMuscleRole,
    showMuscleRole: showMuscleRole,
    ordMuscleRole: ordMuscleRole,
    eqMuscleRole: eqMuscleRole,
    genericExerciseCategory: genericExerciseCategory,
    showExerciseCategory: showExerciseCategory,
    ordExerciseCategory: ordExerciseCategory,
    eqExerciseCategory: eqExerciseCategory,
    writeForeignExerciseCategory: writeForeignExerciseCategory,
    readForeignExerciseCategory: readForeignExerciseCategory,
    genericIntensity: genericIntensity,
    showIntensity: showIntensity,
    ordIntensity: ordIntensity,
    eqIntensity: eqIntensity,
    writeForeignIntensity: writeForeignIntensity,
    readForeignIntensity: readForeignIntensity
};
