// Generated by purs version 0.14.0
"use strict";
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Prelude = require("../Prelude/index.js");
var cls = function (name) {
    return Halogen_HTML_Properties.class_(name);
};
module.exports = {
    cls: cls,
    button: Halogen_HTML.button,
    div: Halogen_HTML.div,
    img: Halogen_HTML.img,
    input: Halogen_HTML.input,
    li: Halogen_HTML.li,
    span: Halogen_HTML.span,
    table: Halogen_HTML.table,
    tbody: Halogen_HTML.tbody,
    td: Halogen_HTML.td,
    text: Halogen_HTML.text,
    th: Halogen_HTML.th,
    thead: Halogen_HTML.thead,
    tr: Halogen_HTML.tr,
    ul: Halogen_HTML.ul,
    onClick: Halogen_HTML_Events.onClick,
    onValueInput: Halogen_HTML_Events.onValueInput,
    EQ: Prelude.EQ,
    GT: Prelude.GT,
    LT: Prelude.LT,
    Applicative: Prelude.Applicative,
    Apply: Prelude.Apply,
    Bind: Prelude.Bind,
    BooleanAlgebra: Prelude.BooleanAlgebra,
    Bounded: Prelude.Bounded,
    Category: Prelude.Category,
    CommutativeRing: Prelude.CommutativeRing,
    Discard: Prelude.Discard,
    DivisionRing: Prelude.DivisionRing,
    Eq: Prelude.Eq,
    EuclideanRing: Prelude.EuclideanRing,
    Field: Prelude.Field,
    Functor: Prelude.Functor,
    HeytingAlgebra: Prelude.HeytingAlgebra,
    Monad: Prelude.Monad,
    Monoid: Prelude.Monoid,
    Ord: Prelude.Ord,
    Ring: Prelude.Ring,
    Semigroup: Prelude.Semigroup,
    Semigroupoid: Prelude.Semigroupoid,
    Semiring: Prelude.Semiring,
    Show: Prelude.Show,
    absurd: Prelude.absurd,
    add: Prelude.add,
    ap: Prelude.ap,
    append: Prelude.append,
    apply: Prelude.apply,
    between: Prelude.between,
    bind: Prelude.bind,
    bottom: Prelude.bottom,
    clamp: Prelude.clamp,
    compare: Prelude.compare,
    comparing: Prelude.comparing,
    compose: Prelude.compose,
    conj: Prelude.conj,
    "const": Prelude["const"],
    degree: Prelude.degree,
    discard: Prelude.discard,
    disj: Prelude.disj,
    eq: Prelude.eq,
    flap: Prelude.flap,
    flip: Prelude.flip,
    gcd: Prelude.gcd,
    identity: Prelude.identity,
    ifM: Prelude.ifM,
    join: Prelude.join,
    lcm: Prelude.lcm,
    liftA1: Prelude.liftA1,
    liftM1: Prelude.liftM1,
    map: Prelude.map,
    max: Prelude.max,
    mempty: Prelude.mempty,
    min: Prelude.min,
    mod: Prelude.mod,
    mul: Prelude.mul,
    negate: Prelude.negate,
    not: Prelude.not,
    notEq: Prelude.notEq,
    one: Prelude.one,
    otherwise: Prelude.otherwise,
    pure: Prelude.pure,
    recip: Prelude.recip,
    show: Prelude.show,
    sub: Prelude.sub,
    top: Prelude.top,
    unit: Prelude.unit,
    unless: Prelude.unless,
    unlessM: Prelude.unlessM,
    "void": Prelude["void"],
    when: Prelude.when,
    whenM: Prelude.whenM,
    zero: Prelude.zero
};
