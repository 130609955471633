// Generated by purs version 0.14.0
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Web_UIEvent_MouseEvent = require("../Web.UIEvent.MouseEvent/index.js");
var WorkoutBuilder_Analysis = require("../WorkoutBuilder.Analysis/index.js");
var WorkoutBuilder_Client_AddExerciseModal = require("../WorkoutBuilder.Client.AddExerciseModal/index.js");
var WorkoutBuilder_Client_Charts_Charts = require("../WorkoutBuilder.Client.Charts.Charts/index.js");
var WorkoutBuilder_Client_EditExerciseModal = require("../WorkoutBuilder.Client.EditExerciseModal/index.js");
var WorkoutBuilder_Client_EditableLabel = require("../WorkoutBuilder.Client.EditableLabel/index.js");
var WorkoutBuilder_Client_Images = require("../WorkoutBuilder.Client.Images/index.js");
var WorkoutBuilder_Client_InfoBar = require("../WorkoutBuilder.Client.InfoBar/index.js");
var WorkoutBuilder_Client_State = require("../WorkoutBuilder.Client.State/index.js");
var WorkoutBuilder_Client_Toggle = require("../WorkoutBuilder.Client.Toggle/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var WorkoutBuilder_Formatting = require("../WorkoutBuilder.Formatting/index.js");
var WorkoutBuilder_Programming = require("../WorkoutBuilder.Programming/index.js");
var WorkoutBuilder_Samples = require("../WorkoutBuilder.Samples/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var showRepVol = function (reps) {
    if (reps.min === reps.max) {
        return Data_Show.show(Data_Show.showInt)(Data_Int.round(reps.min)) + " reps";
    };
    return Data_Show.show(Data_Show.showInt)(Data_Int.round(reps.min)) + (" - " + (Data_Show.show(Data_Show.showInt)(Data_Int.round(reps.max)) + " reps"));
};
var volumeBox = function (title) {
    return function (v) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--medium") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--title") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("label--medium--heavy") ])([ Halogen_HTML_Core.text(title) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title label--small--faded") ])([ Halogen_HTML_Core.text("Weekly Volume") ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(WorkoutBuilder_Formatting.round(v.sets) + " sets") ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(showRepVol(v.reps)) ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(WorkoutBuilder_Formatting.minutesToHourMinutes(WorkoutBuilder_Analysis.volumeWorkoutTime(v))) ]) ]) ]);
    };
};
var showHideBtn = function (isOpen) {
    var img = (function () {
        if (isOpen) {
            return WorkoutBuilder_Client_Images.images.chevronLeft;
        };
        return WorkoutBuilder_Client_Images.images.chevronRight;
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(function (v) {
        return new WorkoutBuilder_Client_State.SetInfoBarIsOpen({
            isOpen: !isOpen
        });
    }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-chevron"), Halogen_HTML_Properties.src(img) ]) ]);
};
var scheduleOption = function (label) {
    return function (days) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("button button--light"), Halogen_HTML_Events.onClick(function (v) {
            return new WorkoutBuilder_Client_State.SetScheduleDays({
                days: days
            });
        }) ])([ Halogen_HTML_Core.text(label) ]);
    };
};
var scheduleOptions = (function () {
    var workout = new WorkoutBuilder_Types.ScheduleWorkout(WorkoutBuilder_Types.emptyScheduleWorkoutDay);
    return [ scheduleOption("Empty week")([ WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ]), scheduleOption("3x Full Body")([ workout, WorkoutBuilder_Types.ScheduleRest.value, workout, WorkoutBuilder_Types.ScheduleRest.value, workout, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ]), scheduleOption("4x Full Body")([ workout, workout, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, workout, workout, WorkoutBuilder_Types.ScheduleRest.value ]), scheduleOption("6x Full Body")([ workout, workout, workout, workout, workout, workout, WorkoutBuilder_Types.ScheduleRest.value ]), scheduleOption("Every Other")([ workout, WorkoutBuilder_Types.ScheduleRest.value ]) ];
})();
var paramsShortcut = function (name) {
    return function (params) {
        return Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(function (v) {
            return new WorkoutBuilder_Client_State.SetParams(params);
        }) ])([ Halogen_HTML_Core.text(name) ]);
    };
};
var noPropagate = function (action) {
    return function (event) {
        return new WorkoutBuilder_Client_State.StopPropagation(Web_UIEvent_MouseEvent.toEvent(event), action);
    };
};
var navBar = function (v) {
    var imgCls = (function () {
        if (v.isOpen) {
            return "btn-chevron--up";
        };
        return "btn-chevron--down";
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("nav-bar") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("nav-bar__side-bar-toggle icon-button"), Halogen_HTML_Events.onClick(function (v1) {
        return new WorkoutBuilder_Client_State.SetInfoBarIsOpen({
            isOpen: !v.isOpen
        });
    }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls(imgCls), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.chevronLeft) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("nav-bar__side-bar__title") ])([ Halogen_HTML_Core.text("Guidelines") ]) ]) ]);
};
var muscleChart = function (program) {
    return function (muscleVolume) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--wide") ])([ WorkoutBuilder_Client_Charts_Charts.stackedBarChart("musclesChart")({
            program: program,
            muscleVolume: muscleVolume
        }) ]);
    };
};
var mkSamplePrograms = Data_Functor.map(Data_Functor.functorArray)(function (p) {
    return paramsShortcut(p.name)(p);
})(WorkoutBuilder_Samples.samplePrograms);
var mkModal = function (v) {
    var modalWrapperClass = (function () {
        if (v.modal instanceof WorkoutBuilder_Client_State.ModalClosed) {
            return "modal-wrapper--closed";
        };
        return "modal-wrapper";
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls(modalWrapperClass) ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-overlay"), Halogen_HTML_Events.onClick(Data_Function["const"](WorkoutBuilder_Client_State.CloseModal.value)) ])([  ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal") ])((function () {
        if (v.modal instanceof WorkoutBuilder_Client_State.ModalAddingExerciseForGroup) {
            return [ WorkoutBuilder_Client_AddExerciseModal.addExerciseModal(v.modal.value0.group) ];
        };
        if (v.modal instanceof WorkoutBuilder_Client_State.ModalEditingExercise) {
            return [ WorkoutBuilder_Client_EditExerciseModal.editExerciseModal(v.modal.value0) ];
        };
        return [  ];
    })()) ]);
};
var itemRow = function (title) {
    return function (buttons) {
        return function (content) {
            return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("row-container") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("row-container__title-bar") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("row-container__title-bar__title title") ])([ Halogen_HTML_Core.text(title) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("row-container__title-bar__buttons") ])(buttons) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("row") ])(content) ]);
        };
    };
};
var intensitySelector = function (group) {
    return function (index) {
        return function (intensity) {
            var removeIntensity = Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag tag--selector"), Halogen_HTML_Events.onClick(noPropagate(new WorkoutBuilder_Client_State.RemoveDupDay({
                group: group.name,
                index: index
            }))) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-close"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.close) ]) ]);
            var activeCls = function (i) {
                var $45 = Data_Eq.eq(WorkoutBuilder_Types.eqIntensity)(i)(intensity);
                if ($45) {
                    return " tag--active";
                };
                return "";
            };
            var intensitySel = function (i) {
                return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag tag--selector" + activeCls(i)), Halogen_HTML_Events.onClick(noPropagate(new WorkoutBuilder_Client_State.SetDupDay({
                    group: group.name,
                    index: index,
                    intensity: i
                }))) ])([ Halogen_HTML_Core.text(Data_Show.show(WorkoutBuilder_Types.showIntensity)(i)) ]);
            };
            return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag-dropdown") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag tag--selected") ])([ Halogen_HTML_Core.text(Data_Show.show(WorkoutBuilder_Types.showIntensity)(intensity)) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag-dropdown__dropdown") ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(intensitySel)(WorkoutBuilder_Types.allIntensities))([ removeIntensity ])) ]);
        };
    };
};
var dumpRepScheme = function (v) {
    if (v.sets === 0 && (v.reps.min === 0 && v.reps.max === 0)) {
        return "";
    };
    if (v.reps.min === v.reps.max) {
        return Data_Show.show(Data_Show.showInt)(v.sets) + ("x" + Data_Show.show(Data_Show.showInt)(v.reps.min));
    };
    return Data_Show.show(Data_Show.showInt)(v.sets) + ("x" + (Data_Show.show(Data_Show.showInt)(v.reps.min) + ("-" + Data_Show.show(Data_Show.showInt)(v.reps.max))));
};
var exerciseRow = function (v) {
    return Halogen_HTML_Elements.tr([ WorkoutBuilder_Client_UiPrelude.cls("text--small") ])([ Halogen_HTML_Elements.td([ WorkoutBuilder_Client_UiPrelude.cls("nowrap") ])([ Halogen_HTML_Core.text("(" + (v.group + ")")) ]), Halogen_HTML_Elements.td([  ])([ Halogen_HTML_Core.text(v.exercise.name) ]), Halogen_HTML_Elements.td([ WorkoutBuilder_Client_UiPrelude.cls("nowrap") ])([ Halogen_HTML_Core.text(dumpRepScheme(v.repScheme)) ]) ]);
};
var dayProgrammingBox = function (params) {
    return function (index) {
        return function (plan) {
            var titleText = (function () {
                if (plan instanceof WorkoutBuilder_Types.ScheduleRest) {
                    return "Rest Day";
                };
                if (plan instanceof WorkoutBuilder_Types.ScheduleWorkout) {
                    return "Workout";
                };
                throw new Error("Failed pattern match at WorkoutBuilder.Client.App (line 236, column 17 - line 238, column 48): " + [ plan.constructor.name ]);
            })();
            var groupToggle = function (selected) {
                return function (group) {
                    var active = Data_Set.member(Data_Ord.ordString)(group)(selected);
                    var toggleAction = (function () {
                        if (active) {
                            return new WorkoutBuilder_Client_State.SetDayGroups({
                                index: index,
                                groups: Data_Set["delete"](Data_Ord.ordString)(group)(selected)
                            });
                        };
                        return new WorkoutBuilder_Client_State.SetDayGroups({
                            index: index,
                            groups: Data_Set.insert(Data_Ord.ordString)(group)(selected)
                        });
                    })();
                    return WorkoutBuilder_Client_Toggle.toggle(group)(toggleAction)(active);
                };
            };
            var allGroups = WorkoutBuilder_Programming.allExerciseGroups(params);
            var allToggle = function (groups) {
                var active = Data_Eq.eq(Data_Set.eqSet(Data_Eq.eqString))(groups)(allGroups);
                var allAction = (function () {
                    if (active) {
                        return new WorkoutBuilder_Client_State.SetDayGroups({
                            index: index,
                            groups: Data_Set.empty
                        });
                    };
                    return new WorkoutBuilder_Client_State.SetDayGroups({
                        index: index,
                        groups: allGroups
                    });
                })();
                return WorkoutBuilder_Client_Toggle.toggle("all")(allAction)(active);
            };
            var selectedGroups = (function () {
                if (plan instanceof WorkoutBuilder_Types.ScheduleRest) {
                    return Data_Set.empty;
                };
                if (plan instanceof WorkoutBuilder_Types.ScheduleWorkout) {
                    var $68 = Data_Set.isEmpty(plan.value0.groups);
                    if ($68) {
                        return allGroups;
                    };
                    return plan.value0.groups;
                };
                throw new Error("Failed pattern match at WorkoutBuilder.Client.App (line 255, column 22 - line 257, column 85): " + [ plan.constructor.name ]);
            })();
            var toggles = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ allToggle(selectedGroups) ])(Data_Functor.map(Data_Functor.functorArray)(groupToggle(selectedGroups))(Data_Array.fromFoldable(Data_Set.foldableSet)(allGroups)));
            return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--large") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--title") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title-bar") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title-bar__label label--medium") ])([ Halogen_HTML_Core.text("Day " + (Data_Show.show(Data_Show.showInt)(index + 1 | 0) + (": " + titleText))) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(function (v) {
                return new WorkoutBuilder_Client_State.RemoveScheduleDay({
                    index: index
                });
            }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-close"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.close) ]) ]) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("toggle-list") ])(toggles) ]) ]);
        };
    };
};
var dayBox = function (dayNum) {
    return function (v) {
        return function (vol) {
            if (v instanceof WorkoutBuilder_Types.RestDay) {
                return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--large") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--title") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("label--medium--light") ])([ Halogen_HTML_Core.text("Day " + Data_Show.show(Data_Show.showInt)(dayNum)) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("(Rest day)") ]) ]) ]);
            };
            if (v instanceof WorkoutBuilder_Types.WorkoutDay) {
                return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--large") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--title") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("label--medium--light") ])([ Halogen_HTML_Core.text("Day " + Data_Show.show(Data_Show.showInt)(dayNum)) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(v.value0.exercises)) + " exercises") ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(WorkoutBuilder_Formatting.round(vol.sets) + " sets") ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(showRepVol(vol.reps)) ]), Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(WorkoutBuilder_Formatting.minutesToHourMinutes(WorkoutBuilder_Analysis.workoutTimeInMinutes(v))) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text(Data_String_Common.joinWith(", ")(Data_Array.nubEq(Data_Eq.eqString)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                    return v1.group;
                })(v.value0.exercises)))) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.table([ WorkoutBuilder_Client_UiPrelude.cls("exercise-table") ])([ Halogen_HTML_Elements.tbody([  ])(Data_Functor.map(Data_Functor.functorArray)(exerciseRow)(v.value0.exercises)) ]) ]) ]);
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.App (line 368, column 1 - line 368, column 59): " + [ dayNum.constructor.name, v.constructor.name, vol.constructor.name ]);
        };
    };
};
var addScheduleDay = Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(function (v) {
    return WorkoutBuilder_Client_State.AddScheduleDay.value;
}) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-add"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.add) ]) ]);
var addIntensity = function (group) {
    var intensitySel = function (i) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag tag--selector"), Halogen_HTML_Events.onClick(noPropagate(new WorkoutBuilder_Client_State.AddDupDay({
            group: group.name,
            intensity: i
        }))) ])([ Halogen_HTML_Core.text(Data_Show.show(WorkoutBuilder_Types.showIntensity)(i)) ]);
    };
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag-dropdown tag-dropdown--add") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag tag--selected") ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-add btn-add--small"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.add) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag-dropdown__dropdown") ])(Data_Functor.map(Data_Functor.functorArray)(intensitySel)(WorkoutBuilder_Types.allIntensities)) ]);
};
var periodizationSelector = function (group) {
    return function (schedule) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("tag-list") ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Array.mapWithIndex(intensitySelector(group))(schedule))([ addIntensity(group) ]));
    };
};
var groupBox = function (dictMonadEffect) {
    return function (index) {
        return function (group) {
            var exerciseItem = function (index1) {
                return function (v) {
                    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("exercise-entry--button"), Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.ShowExerciseInfo({
                        group: group,
                        exercise: v,
                        index: index1
                    }))) ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("exercise-entry__label") ])([ Halogen_HTML_Core.text(v.name) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button") ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-settings"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.settings) ]) ]) ]);
                };
            };
            var addExercise = Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("exercise-entry--button--center"), Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.ShowAddExerciseModal({
                group: group
            }))) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("exercise-entry__btn btn-add"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.add) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("exercise-entry__label") ])([ Halogen_HTML_Core.text("Add Exercise") ]) ]);
            return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box box--large") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--title") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title-bar") ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                return "groupTitle";
            }))(Data_Ord.ordInt)(Type_Proxy["Proxy"].value)(index)(WorkoutBuilder_Client_EditableLabel.editableLabel(dictMonadEffect))({
                label: group.name,
                extraClass: new Data_Maybe.Just("title-bar__label"),
                size: WorkoutBuilder_Client_EditableLabel.Small.value
            })(function (v) {
                return new WorkoutBuilder_Client_State.SetGroupName({
                    index: index,
                    name: v.value0.label
                });
            }), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(function (v) {
                return new WorkoutBuilder_Client_State.RemoveGroup({
                    index: index
                });
            }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-delete"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images["images"]["delete"]) ]) ]) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title label--small--faded") ])([ Halogen_HTML_Core.text("Exercises") ]), Halogen_HTML_Elements.div([  ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Array.mapWithIndex(exerciseItem)(group.exercises))([ addExercise ])) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title label--small--faded") ])([ Halogen_HTML_Core.text("Target Weekly Sets") ]), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showInt)(group.targetSets)), Halogen_HTML_Events.onValueInput(function (e) {
                return new WorkoutBuilder_Client_State.SetTargetSets({
                    group: group.name,
                    sets: e
                });
            }) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("box__section box__section--clickable"), Halogen_HTML_Events.onClick(function (v) {
                return new WorkoutBuilder_Client_State.TogglePeriodization({
                    group: group.name
                });
            }) ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("title label--small--faded") ])([ Halogen_HTML_Core.text("Periodization") ]), (function () {
                if (group.periodization instanceof WorkoutBuilder_Types.DailyUndulating) {
                    return periodizationSelector(group)(group.periodization.value0.schedule);
                };
                return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("label--small") ])([ Halogen_HTML_Core.text("None") ]);
            })() ]) ]);
        };
    };
};
var addGroup = Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("button--light icon-button"), Halogen_HTML_Events.onClick(function (v) {
    return WorkoutBuilder_Client_State.AddGroup.value;
}) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-add"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.add) ]), Halogen_HTML_Core.text("New Group") ]);
var mkContent = function (dictMonadEffect) {
    return function (workoutParams) {
        var program = WorkoutBuilder_Programming.buildProgram(workoutParams);
        var volume = WorkoutBuilder_Analysis.programVolume(program);
        var days = Data_Array.zip(program.days)(volume.day);
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("content__main") ])([ itemRow("Sample Programs")([  ])(mkSamplePrograms), itemRow("Program")([  ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
            return "programTitle";
        }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(WorkoutBuilder_Client_EditableLabel.editableLabel(dictMonadEffect))({
            label: workoutParams.name,
            extraClass: Data_Maybe.Nothing.value,
            size: WorkoutBuilder_Client_EditableLabel.Large.value
        })(WorkoutBuilder_Client_State.SetProgramName.create) ]), itemRow("Exercises (By Group)")([ addGroup ])(Data_Array.mapWithIndex(groupBox(dictMonadEffect))(workoutParams.groups)), itemRow("Schedule")(Data_Semigroup.append(Data_Semigroup.semigroupArray)(scheduleOptions)([ addScheduleDay ]))(Data_Array.mapWithIndex(dayProgrammingBox(workoutParams))(workoutParams.days)), itemRow("Program Overview")([  ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ volumeBox("Overall")(volume.total) ])(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return volumeBox(v.group)(v.volume);
        })(volume.groups))), itemRow("Program Volume by Muscle")([  ])([ muscleChart(program)(volume.muscles) ]), itemRow("Program: Week 1")([  ])(Data_Array.mapWithIndex(function (i) {
            return function (v) {
                return dayBox(i + 1 | 0)(v.value0)(v.value1);
            };
        })(Data_Array.take(7)(days))), itemRow("Program: Week 2")([  ])(Data_Array.mapWithIndex(function (i) {
            return function (v) {
                return dayBox(i + 8 | 0)(v.value0)(v.value1);
            };
        })(Data_Array.take(7)(Data_Array.drop(7)(days)))), itemRow("Program: Week 3")([  ])(Data_Array.mapWithIndex(function (i) {
            return function (v) {
                return dayBox((i + 14 | 0) + 1 | 0)(v.value0)(v.value1);
            };
        })(Data_Array.take(7)(Data_Array.drop(14)(days)))), itemRow("Program: Week 4")([  ])(Data_Array.mapWithIndex(function (i) {
            return function (v) {
                return dayBox((i + 21 | 0) + 1 | 0)(v.value0)(v.value1);
            };
        })(Data_Array.take(7)(Data_Array.drop(21)(days)))) ]);
    };
};
var render = function (dictMonadEffect) {
    return function (state) {
        var sideBarClass = (function () {
            if (state.infoBar.isOpen) {
                return " content--sidebar-open";
            };
            if (!state.infoBar.isOpen) {
                return "";
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.App (line 75, column 20 - line 77, column 18): " + [ state.infoBar.isOpen.constructor.name ]);
        })();
        var modalClass = (function () {
            if (state.modal instanceof WorkoutBuilder_Client_State.ModalClosed) {
                return " content--modal-closed";
            };
            return "";
        })();
        return Halogen_HTML_Elements.div([  ])([ navBar(state.infoBar), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("content" + (modalClass + sideBarClass)) ])([ WorkoutBuilder_Client_InfoBar.infoBar(state.infoBar), Halogen_HTML.lazy(mkContent(dictMonadEffect))(state.workoutParams), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("content__sidebar-overlay") ])([  ]), mkModal(state) ]) ]);
    };
};
var component = function (dictMonadEffect) {
    return function (workoutParams) {
        return Halogen_Component.mkComponent({
            initialState: function (v) {
                return {
                    workoutParams: workoutParams,
                    lastPeriodization: Data_Map_Internal.empty,
                    infoBar: {
                        isOpen: true
                    },
                    modal: WorkoutBuilder_Client_State.ModalClosed.value
                };
            },
            render: Halogen_HTML.lazy(render(dictMonadEffect)),
            "eval": Halogen_Component.mkEval({
                handleAction: WorkoutBuilder_Client_State.handleAction(dictMonadEffect),
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: Halogen_Component.defaultEval.initialize,
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
module.exports = {
    component: component,
    render: render,
    navBar: navBar,
    showHideBtn: showHideBtn,
    mkModal: mkModal,
    mkContent: mkContent,
    mkSamplePrograms: mkSamplePrograms,
    muscleChart: muscleChart,
    scheduleOptions: scheduleOptions,
    scheduleOption: scheduleOption,
    addScheduleDay: addScheduleDay,
    addGroup: addGroup,
    itemRow: itemRow,
    paramsShortcut: paramsShortcut,
    dayProgrammingBox: dayProgrammingBox,
    groupBox: groupBox,
    periodizationSelector: periodizationSelector,
    intensitySelector: intensitySelector,
    addIntensity: addIntensity,
    noPropagate: noPropagate,
    volumeBox: volumeBox,
    dayBox: dayBox,
    exerciseRow: exerciseRow,
    dumpRepScheme: dumpRepScheme,
    showRepVol: showRepVol
};
