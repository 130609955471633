// Generated by purs version 0.14.0
"use strict";
var images = {
    add: "add_black_24dp.e850be33.svg",
    check: "done_black_24dp.f283b952.svg",
    chevronLeft: "chevron_left_24dp.f7a815b8.svg",
    chevronRight: "chevron_right_24dp.5ba22248.svg",
    close: "close_black_24dp.4643e6f6.svg",
    "delete": "delete_24dp.1199a294.svg",
    edit: "edit_24dp.8fa38ae5.svg",
    info: "info_24dp.613e7696.svg",
    settings: "settings_24dp.8917e8ad.svg"
};
module.exports = {
    images: images
};
