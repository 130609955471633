// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Halogen_Aff_Util = require("../Halogen.Aff.Util/index.js");
var Halogen_VDom_Driver = require("../Halogen.VDom.Driver/index.js");
var WorkoutBuilder_Client_App = require("../WorkoutBuilder.Client.App/index.js");
var WorkoutBuilder_Client_UrlCodecs = require("../WorkoutBuilder.Client.UrlCodecs/index.js");
var WorkoutBuilder_Samples = require("../WorkoutBuilder.Samples/index.js");
var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WorkoutBuilder_Client_UrlCodecs.readParamsFromUrlHash))(function (hashParams) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)((function () {
            if (hashParams instanceof Data_Either.Left) {
                return Effect_Class_Console.log(Effect_Aff.monadEffectAff)(hashParams.value0);
            };
            if (hashParams instanceof Data_Either.Right) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.Main (line 23, column 3 - line 25, column 25): " + [ hashParams.constructor.name ]);
        })())(function () {
            var params = Data_Either.fromRight(WorkoutBuilder_Samples.rrParams)(hashParams);
            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WorkoutBuilder_Client_UrlCodecs.writeParamsToUrlHash(params)))(function () {
                return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_VDom_Driver.runUI(WorkoutBuilder_Client_App.component(Effect_Aff.monadEffectAff)(params))(Data_Unit.unit)(body))(function () {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                });
            });
        });
    });
}));
module.exports = {
    main: main
};
