// Generated by purs version 0.14.0
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var WorkoutBuilder_Client_Images = require("../WorkoutBuilder.Client.Images/index.js");
var WorkoutBuilder_Client_State = require("../WorkoutBuilder.Client.State/index.js");
var WorkoutBuilder_Client_Switch = require("../WorkoutBuilder.Client.Switch/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var WorkoutBuilder_Muscles = require("../WorkoutBuilder.Muscles/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var muscleListItem = function (v) {
    var switchOptions = [ {
        label: "Target",
        value: WorkoutBuilder_Types.TargetMuscle.value
    }, {
        label: "Supporting",
        value: WorkoutBuilder_Types.SynergistMuscle.value
    }, {
        label: "Stabilizer",
        value: WorkoutBuilder_Types.StabilizerMuscle.value
    } ];
    var setRole = function (v1) {
        if (!v1.isActive) {
            return new WorkoutBuilder_Client_State.EditExerciseSetMuscleRole({
                muscle: v.value1,
                role: new Data_Maybe.Just(v1.value)
            });
        };
        if (v1.isActive) {
            return new WorkoutBuilder_Client_State.EditExerciseSetMuscleRole({
                muscle: v.value1,
                role: Data_Maybe.Nothing.value
            });
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditExerciseModal (line 134, column 5 - line 134, column 45): " + [ v1.constructor.name ]);
    };
    var fontClass = (function () {
        if (v.value0 instanceof Data_Maybe.Just) {
            return " label--medium--green";
        };
        if (v.value0 instanceof Data_Maybe.Nothing) {
            return " label--medium--faded";
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditExerciseModal (line 130, column 17 - line 132, column 41): " + [ v.value0.constructor.name ]);
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("muscle-list__item") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("muscle-list__cell muscle-list__item__label" + fontClass) ])([ Halogen_HTML_Core.text(v.value1) ]), WorkoutBuilder_Client_Switch["switch"](WorkoutBuilder_Types.eqMuscleRole)(switchOptions)(setRole)(v.value0) ]);
};
var musclesList = function (v) {
    var addMuscleRole = function (m) {
        if (Data_Set.member(Data_Ord.ordString)(m)(v.target)) {
            return new Data_Tuple.Tuple(new Data_Maybe.Just(WorkoutBuilder_Types.TargetMuscle.value), m);
        };
        if (Data_Set.member(Data_Ord.ordString)(m)(v.synergists)) {
            return new Data_Tuple.Tuple(new Data_Maybe.Just(WorkoutBuilder_Types.SynergistMuscle.value), m);
        };
        if (Data_Set.member(Data_Ord.ordString)(m)(v.stabilizers)) {
            return new Data_Tuple.Tuple(new Data_Maybe.Just(WorkoutBuilder_Types.StabilizerMuscle.value), m);
        };
        return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, m);
    };
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("muscle-list") ])(Data_Functor.map(Data_Functor.functorArray)(muscleListItem)(Data_Functor.map(Data_Functor.functorArray)(addMuscleRole)(Data_Array.sort(Data_Ord.ordString)(WorkoutBuilder_Muscles.allMuscles))));
};
var intensityName = function (v) {
    if (v instanceof WorkoutBuilder_Types.VH) {
        return "Very Heavy";
    };
    if (v instanceof WorkoutBuilder_Types.H) {
        return "Heavy";
    };
    if (v instanceof WorkoutBuilder_Types.M) {
        return "Moderate";
    };
    if (v instanceof WorkoutBuilder_Types.L) {
        return "Light";
    };
    if (v instanceof WorkoutBuilder_Types.VL) {
        return "Very Light";
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Client.EditExerciseModal (line 98, column 1 - line 98, column 37): " + [ v.constructor.name ]);
};
var repRangeInput = function (schemes) {
    return function (intensity) {
        var title = intensityName(intensity);
        var repScheme = Data_Maybe.fromMaybe({
            sets: 1,
            reps: {
                min: 5,
                max: 8
            }
        })(Data_Map_Internal.lookup(WorkoutBuilder_Types.ordIntensity)(intensity)(schemes));
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("rep-range-input") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("rep-range-input__label") ])([ Halogen_HTML_Core.text(title) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("rep-range-input__inputs") ])([ Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showInt)(repScheme.reps.min)), Halogen_HTML_Events.onValueInput((function () {
            var $38 = Data_Maybe.fromMaybe(repScheme.reps.min);
            return function ($39) {
                return (function (val) {
                    return new WorkoutBuilder_Client_State.EditExerciseSetRepScheme({
                        intensity: intensity,
                        scheme: {
                            sets: repScheme.sets,
                            reps: {
                                min: val,
                                max: repScheme.reps.max
                            }
                        }
                    });
                })($38(Data_Int.fromString($39)));
            };
        })()) ]), Halogen_HTML_Core.text("-"), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showInt)(repScheme.reps.max)), Halogen_HTML_Events.onValueInput((function () {
            var $40 = Data_Maybe.fromMaybe(repScheme.reps.max);
            return function ($41) {
                return (function (val) {
                    return new WorkoutBuilder_Client_State.EditExerciseSetRepScheme({
                        intensity: intensity,
                        scheme: {
                            sets: repScheme.sets,
                            reps: {
                                min: repScheme.reps.min,
                                max: val
                            }
                        }
                    });
                })($40(Data_Int.fromString($41)));
            };
        })()) ]), Halogen_HTML_Core.text("reps") ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("rep-range-input__inputs") ])([ Halogen_HTML_Core.text("Min sets:"), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showInt)(repScheme.sets)), Halogen_HTML_Events.onValueInput((function () {
            var $42 = Data_Maybe.fromMaybe(repScheme.sets);
            return function ($43) {
                return (function (val) {
                    return new WorkoutBuilder_Client_State.EditExerciseSetRepScheme({
                        intensity: intensity,
                        scheme: {
                            sets: val,
                            reps: repScheme.reps
                        }
                    });
                })($42(Data_Int.fromString($43)));
            };
        })()) ]) ]) ]);
    };
};
var formInput = function (label) {
    return function (content) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("form-input") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("form-input__label") ])([ Halogen_HTML_Core.text(label) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("form-input__input") ])([ content ]) ]);
    };
};
var categorySwitch = function (category) {
    var setCategory = function (v) {
        return new WorkoutBuilder_Client_State.EditExerciseSetCategory({
            category: v.value
        });
    };
    var options = [ {
        label: "Compound",
        value: WorkoutBuilder_Types.Compound.value
    }, {
        label: "Isolation",
        value: WorkoutBuilder_Types.Isolation.value
    } ];
    return WorkoutBuilder_Client_Switch["switch"](WorkoutBuilder_Types.eqExerciseCategory)(options)(setCategory)(new Data_Maybe.Just(category));
};
var editExerciseModal = function (v) {
    var saveEnabled = Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
        return "scheme";
    }))(Data_Map_Internal.eqMap(WorkoutBuilder_Types.eqIntensity)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Data_Eq.eqInt)))))))()(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
        return "muscles";
    }))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
        return "target";
    }))(Data_Set.eqSet(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
        return "synergists";
    }))(Data_Set.eqSet(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
        return "stabilizers";
    }))(Data_Set.eqSet(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
        return "category";
    }))(WorkoutBuilder_Types.eqExerciseCategory))))(Data_Array.index(v.group.exercises)(v.index))(new Data_Maybe.Just(v.exercise));
    var saveOnClick = (function () {
        if (saveEnabled) {
            return [ Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.SaveEditedExercise({
                group: v.group,
                index: v.index,
                exercise: v.exercise
            }))) ];
        };
        return [  ];
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-content") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-title-bar") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-title-bar__left") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-title") ])([ Halogen_HTML_Core.text("Edit " + v.exercise.name) ]), Halogen_HTML_Elements.button(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ WorkoutBuilder_Client_UiPrelude.cls((function () {
        if (saveEnabled) {
            return "";
        };
        return "btn--disabled";
    })()) ])(saveOnClick))([ Halogen_HTML_Core.text("Save") ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(Data_Function["const"](WorkoutBuilder_Client_State.CloseModal.value)) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-close"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.close) ]) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-body") ])([ formInput("Name:")(Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(v.exercise.name), Halogen_HTML_Events.onValueInput(function (value) {
        return new WorkoutBuilder_Client_State.EditExerciseSetName({
            name: value
        });
    }) ])), formInput("Exercise type:")(categorySwitch(v.exercise.category)), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("form-label") ])([ Halogen_HTML_Core.text("Rep ranges:") ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("rep-range-inputs") ])(Data_Functor.map(Data_Functor.functorArray)(repRangeInput(v.exercise.scheme))(WorkoutBuilder_Types.allIntensities)), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("form-label") ])([ Halogen_HTML_Core.text("Muscles used in exercise:") ]), musclesList(v.exercise.muscles), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.RemoveExercise({
        group: v.group,
        index: v.index
    }))) ])([ Halogen_HTML_Core.text("Delete Exercise") ]) ]) ]);
};
module.exports = {
    editExerciseModal: editExerciseModal,
    formInput: formInput,
    categorySwitch: categorySwitch,
    repRangeInput: repRangeInput,
    intensityName: intensityName,
    musclesList: musclesList,
    muscleListItem: muscleListItem
};
