// Generated by purs version 0.14.0
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var $$switch = function (dictEq) {
    return function (options) {
        return function (onClick) {
            return function (activeOption) {
                var switchCls = function (opt) {
                    if (Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(new Data_Maybe.Just(opt))(activeOption)) {
                        return "switch__option--active";
                    };
                    return "switch__option";
                };
                var option = function (v) {
                    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls(switchCls(v.value)), Halogen_HTML_Events.onClick(Data_Function["const"](onClick({
                        isActive: Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(new Data_Maybe.Just(v.value))(activeOption),
                        value: v.value
                    }))) ])([ Halogen_HTML_Core.text(v.label) ]);
                };
                return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("switch") ])(Data_Functor.map(Data_Functor.functorArray)(option)(options));
            };
        };
    };
};
module.exports = {
    "switch": $$switch
};
