// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Web_UIEvent_KeyboardEvent = require("../Web.UIEvent.KeyboardEvent/index.js");
var WorkoutBuilder_Client_Images = require("../WorkoutBuilder.Client.Images/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var Saved = (function () {
    function Saved(value0) {
        this.value0 = value0;
    };
    Saved.create = function (value0) {
        return new Saved(value0);
    };
    return Saved;
})();
var Small = (function () {
    function Small() {

    };
    Small.value = new Small();
    return Small;
})();
var Large = (function () {
    function Large() {

    };
    Large.value = new Large();
    return Large;
})();
var OpenEdit = (function () {
    function OpenEdit() {

    };
    OpenEdit.value = new OpenEdit();
    return OpenEdit;
})();
var CloseWithoutSave = (function () {
    function CloseWithoutSave() {

    };
    CloseWithoutSave.value = new CloseWithoutSave();
    return CloseWithoutSave;
})();
var CloseWithSave = (function () {
    function CloseWithSave() {

    };
    CloseWithSave.value = new CloseWithSave();
    return CloseWithSave;
})();
var OnEdit = (function () {
    function OnEdit(value0) {
        this.value0 = value0;
    };
    OnEdit.create = function (value0) {
        return new OnEdit(value0);
    };
    return OnEdit;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var Noop = (function () {
    function Noop() {

    };
    Noop.value = new Noop();
    return Noop;
})();
var render = function (v) {
    var labelClass = (function () {
        if (v.size instanceof Small) {
            return "editable-label--small";
        };
        if (v.size instanceof Large) {
            return "editable-label--large";
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditableLabel (line 80, column 18 - line 82, column 39): " + [ v.size.constructor.name ]);
    })();
    var extraClass_ = (function () {
        if (v.extraClass instanceof Data_Maybe.Just) {
            return " " + v.extraClass.value0;
        };
        if (v.extraClass instanceof Data_Maybe.Nothing) {
            return "";
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditableLabel (line 77, column 19 - line 79, column 33): " + [ v.extraClass.constructor.name ]);
    })();
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls(labelClass + extraClass_) ])((function () {
        if (v.editing) {
            return [ Halogen_HTML_Elements.input([ WorkoutBuilder_Client_UiPrelude.cls("editable-label__input"), Halogen_HTML_Properties.value(v.label), Halogen_HTML_Events.onValueInput(function (newLabel) {
                return new OnEdit({
                    label: newLabel
                });
            }), Halogen_HTML_Events.onKeyUp(function ($50) {
                return (function (v1) {
                    if (v1 === "Enter") {
                        return CloseWithSave.value;
                    };
                    return Noop.value;
                })(Web_UIEvent_KeyboardEvent.key($50));
            }) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("button--light icon-button"), Halogen_HTML_Events.onClick(function (v1) {
                return CloseWithSave.value;
            }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-check"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.check) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("editable-label__button button--light icon-button"), Halogen_HTML_Events.onClick(function (v1) {
                return CloseWithoutSave.value;
            }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-close"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.close) ]) ]) ];
        };
        if (!v.editing) {
            return [ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("editable-label__label") ])([ Halogen_HTML_Core.text(v.label) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("button--light icon-button"), Halogen_HTML_Events.onClick(function (v1) {
                return OpenEdit.value;
            }) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-edit"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.edit) ]) ]) ];
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditableLabel (line 56, column 5 - line 74, column 17): " + [ v.editing.constructor.name ]);
    })());
};
var handleAction = function (dictMonadEffect) {
    return function (action) {
        if (action instanceof Noop) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (action instanceof OpenEdit) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $26 = {};
                for (var $27 in s) {
                    if ({}.hasOwnProperty.call(s, $27)) {
                        $26[$27] = s[$27];
                    };
                };
                $26.editing = true;
                return $26;
            });
        };
        if (action instanceof OnEdit) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $29 = {};
                for (var $30 in s) {
                    if ({}.hasOwnProperty.call(s, $30)) {
                        $29[$30] = s[$30];
                    };
                };
                $29.label = action.value0.label;
                return $29;
            });
        };
        if (action instanceof CloseWithoutSave) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $34 = {};
                for (var $35 in s) {
                    if ({}.hasOwnProperty.call(s, $35)) {
                        $34[$35] = s[$35];
                    };
                };
                $34.editing = false;
                return $34;
            });
        };
        if (action instanceof CloseWithSave) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                    var $38 = {};
                    for (var $39 in s) {
                        if ({}.hasOwnProperty.call(s, $39)) {
                            $38[$39] = s[$39];
                        };
                    };
                    $38.editing = false;
                    return $38;
                }))(function () {
                    return Halogen_Query_HalogenM.raise(new Saved({
                        label: v.label
                    }));
                });
            });
        };
        if (action instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $42 = {};
                for (var $43 in s) {
                    if ({}.hasOwnProperty.call(s, $43)) {
                        $42[$43] = s[$43];
                    };
                };
                $42.label = action.value0.label;
                $42.extraClass = action.value0.extraClass;
                return $42;
            });
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.EditableLabel (line 85, column 23 - line 95, column 77): " + [ action.constructor.name ]);
    };
};
var editableLabel = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: function (v) {
            return {
                editing: false,
                label: v.label,
                extraClass: v.extraClass,
                size: v.size
            };
        },
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($51) {
                return Data_Maybe.Just.create(Receive.create($51));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    OpenEdit: OpenEdit,
    CloseWithoutSave: CloseWithoutSave,
    CloseWithSave: CloseWithSave,
    OnEdit: OnEdit,
    Receive: Receive,
    Noop: Noop,
    Small: Small,
    Large: Large,
    Saved: Saved,
    editableLabel: editableLabel,
    render: render,
    handleAction: handleAction
};
