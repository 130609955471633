// Generated by purs version 0.14.0
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var WorkoutBuilder_Muscles = require("../WorkoutBuilder.Muscles/index.js");
var WorkoutBuilder_OwnPrelude = require("../WorkoutBuilder.OwnPrelude/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var weightedRingPushUp = {
    name: "Weighted ring push-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.serratusAnterior, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.soleus, WorkoutBuilder_Muscles.erectorSpinae ])
    }
};
var weightedRingDip = {
    name: "Weighted ring dip",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.teresMajor ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsLower ])
    }
};
var weightedHang = {
    name: "Weighted hang (grip)",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var weightedDip = {
    name: "Weighted bar dip",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.teresMajor ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsLower ])
    }
};
var straddleSplits = {
    name: "Straddle splits",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var squat = {
    name: "Back squat",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.quadriceps ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.soleus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques ])
    }
};
var singleLegRdl = {
    name: "Single leg RDL",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.adductorMagnus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.gluteusMinimus, WorkoutBuilder_Muscles.quadratusLumborum, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.rectusAbdominus ])
    }
};
var shrug = {
    name: "Dumbbell Shrug",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsUpper ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.levatorScapulae ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.erectorSpinae ])
    }
};
var shrimpSquat = {
    name: "Shrimp squat",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.quadriceps ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.soleus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.gluteusMedius, WorkoutBuilder_Muscles.gluteusMinimus, WorkoutBuilder_Muscles.quadratusLumborum, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.rectusAbdominus ])
    }
};
var romanianDeadlift = {
    name: "Romanian deadlift",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.adductorMagnus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques ])
    }
};
var ringPushUp = {
    name: "Ring push-up progression",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.serratusAnterior, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.soleus, WorkoutBuilder_Muscles.erectorSpinae ])
    }
};
var ringDip = {
    name: "Ring dip progression",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.teresMajor ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsLower ])
    }
};
var pushUp = {
    name: "Push-up progression",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.erectorSpinae ])
    }
};
var pullUp = {
    name: "Pull-up progression",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.lats ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis, WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.teresMajor, WorkoutBuilder_Muscles.deltoidPosterior, WorkoutBuilder_Muscles.infraspinatus, WorkoutBuilder_Muscles.teresMinor, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.pecsMinor ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.triceps ])
    }
};
var weightedPullUp = {
    name: "Weighted pull-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: pullUp.muscles
};
var pseudoPlanchePushUp = {
    name: "Pseudo planche push-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.biceps ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.erectorSpinae ])
    }
};
var pistolSquat = {
    name: "Pistol squat",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.quadriceps ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.soleus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.gluteusMedius, WorkoutBuilder_Muscles.gluteusMinimus, WorkoutBuilder_Muscles.quadratusLumborum, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.iliopsoas, WorkoutBuilder_Muscles.tensorFasciaeLatae, WorkoutBuilder_Muscles.pectineus, WorkoutBuilder_Muscles.sartorius ])
    }
};
var pikePushUp = {
    name: "Pike push-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.deltoidLateral, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.serratusAnterior ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.levatorScapulae ])
    }
};
var pikePullThrough = {
    name: "Pike pull-through",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.rectusAbdominus ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var pancakeStretch = {
    name: "Pancake stretch",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var oneArmPullUp = {
    name: "One-arm pull-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: pullUp.muscles
};
var nordicCurl = {
    name: "Nordic curl",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.gracilis, WorkoutBuilder_Muscles.sartorius, WorkoutBuilder_Muscles.popliteus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques, WorkoutBuilder_Muscles.rectusFemoris, WorkoutBuilder_Muscles.tibialisAnterior ])
    }
};
var militaryPress = {
    name: "Barbell military press",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.deltoidLateral, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.serratusAnterior ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.levatorScapulae ])
    }
};
var legCurl = {
    name: "Leg curl",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.gracilis, WorkoutBuilder_Muscles.sartorius, WorkoutBuilder_Muscles.popliteus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.tibialisAnterior ])
    }
};
var lateralRaise = {
    name: "Dumbbell Lateral Raise",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidLateral ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.supraspinatus, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.serratusAnterior ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.wristExtensors ])
    }
};
var hyperextension = {
    name: "Hyperextension",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.erectorSpinae ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.adductorMagnus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var hangingLegRaise = {
    name: "Hanging leg raise",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.rectusAbdominus ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.iliopsoas, WorkoutBuilder_Muscles.tensorFasciaeLatae, WorkoutBuilder_Muscles.pectineus, WorkoutBuilder_Muscles.sartorius, WorkoutBuilder_Muscles.adductorLongus, WorkoutBuilder_Muscles.adductorBrevis, WorkoutBuilder_Muscles.obliques ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.rectusFemoris ])
    }
};
var handstandPushUp = {
    name: "Handstand Push-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: pikePushUp.muscles
};
var frontLeverRow = {
    name: "Front lever row",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.teresMajor, WorkoutBuilder_Muscles.deltoidPosterior, WorkoutBuilder_Muscles.infraspinatus, WorkoutBuilder_Muscles.teresMinor, WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis, WorkoutBuilder_Muscles.pecsLower ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.triceps, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques ])
    }
};
var oneArmRow = {
    name: "One arm row",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: frontLeverRow.muscles
};
var weightedHorizontalRow = {
    name: "Weighted horizontal row",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: frontLeverRow.muscles
};
var dumbbellRow = {
    name: "Dumbbell bent-over row",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.teresMajor, WorkoutBuilder_Muscles.deltoidPosterior, WorkoutBuilder_Muscles.infraspinatus, WorkoutBuilder_Muscles.teresMinor, WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis, WorkoutBuilder_Muscles.pecsUpper ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.triceps ])
    }
};
var deadlift = {
    name: "Deadlift",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.quadriceps, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.soleus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.rectusAbdominus, WorkoutBuilder_Muscles.obliques ])
    }
};
var copenhagenPlank = {
    name: "Copenhagen plank",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.obliques ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMedius, WorkoutBuilder_Muscles.gluteusMinimus, WorkoutBuilder_Muscles.tensorFasciaeLatae, WorkoutBuilder_Muscles.quadratusLumborum, WorkoutBuilder_Muscles.psoasMajor, WorkoutBuilder_Muscles.iliocastalisLumborum, WorkoutBuilder_Muscles.iliocastalisThoracis, WorkoutBuilder_Muscles.hipAdductors, WorkoutBuilder_Muscles.pectineus, WorkoutBuilder_Muscles.gracilis, WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.pecsLower, WorkoutBuilder_Muscles.levatorScapulae ])
    }
};
var chinUp = {
    name: "Chin-up progression",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.lats ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis, WorkoutBuilder_Muscles.teresMajor, WorkoutBuilder_Muscles.deltoidPosterior, WorkoutBuilder_Muscles.rhomboids, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.trapsLower, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.pecsLower, WorkoutBuilder_Muscles.pecsMinor ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.triceps ])
    }
};
var bulgarianSplitSquat = {
    name: "Bulgarian split squat",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.quadriceps ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.gluteusMaximus, WorkoutBuilder_Muscles.adductorMagnus, WorkoutBuilder_Muscles.soleus ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.hamstrings, WorkoutBuilder_Muscles.gastrocnemius, WorkoutBuilder_Muscles.erectorSpinae, WorkoutBuilder_Muscles.gluteusMedius, WorkoutBuilder_Muscles.gluteusMinimus ])
    }
};
var bicepCurl = {
    name: "Dumbbell Curl",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Isolation.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.trapsUpper, WorkoutBuilder_Muscles.trapsMiddle, WorkoutBuilder_Muscles.levatorScapulae, WorkoutBuilder_Muscles.wristFlexors ])
    }
};
var benchPress = {
    name: "Bench press",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsUpper ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.pecsLower, WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.triceps ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps ])
    }
};
var backLever = {
    name: "Back lever",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ]),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ WorkoutBuilder_Muscles.biceps, WorkoutBuilder_Muscles.brachialis, WorkoutBuilder_Muscles.brachioradialis, WorkoutBuilder_Muscles.deltoidAnterior, WorkoutBuilder_Muscles.lats, WorkoutBuilder_Muscles.pecsLower, WorkoutBuilder_Muscles.pecsUpper, WorkoutBuilder_Muscles.pecsMinor, WorkoutBuilder_Muscles.serratusAnterior, WorkoutBuilder_Muscles.teresMajor, WorkoutBuilder_Muscles.wristFlexors ]),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])
    }
};
var archerPullUp = {
    name: "Archer pull-up",
    scheme: WorkoutBuilder_Types.defaultRepSchemes,
    category: WorkoutBuilder_Types.Compound.value,
    muscles: pullUp.muscles
};
var allExercises = [ archerPullUp, backLever, benchPress, bicepCurl, bulgarianSplitSquat, chinUp, copenhagenPlank, deadlift, dumbbellRow, frontLeverRow, handstandPushUp, hangingLegRaise, hyperextension, lateralRaise, legCurl, militaryPress, nordicCurl, oneArmPullUp, oneArmRow, pancakeStretch, pikePullThrough, pikePushUp, pistolSquat, pseudoPlanchePushUp, pullUp, pushUp, ringDip, romanianDeadlift, shrimpSquat, shrug, singleLegRdl, squat, straddleSplits, weightedDip, weightedHang, weightedHorizontalRow, weightedPullUp, weightedRingPushUp ];
module.exports = {
    allExercises: allExercises,
    bulgarianSplitSquat: bulgarianSplitSquat,
    pistolSquat: pistolSquat,
    shrimpSquat: shrimpSquat,
    squat: squat,
    singleLegRdl: singleLegRdl,
    nordicCurl: nordicCurl,
    weightedDip: weightedDip,
    ringDip: ringDip,
    weightedRingDip: weightedRingDip,
    pushUp: pushUp,
    ringPushUp: ringPushUp,
    weightedRingPushUp: weightedRingPushUp,
    pikePushUp: pikePushUp,
    handstandPushUp: handstandPushUp,
    pseudoPlanchePushUp: pseudoPlanchePushUp,
    pullUp: pullUp,
    archerPullUp: archerPullUp,
    oneArmPullUp: oneArmPullUp,
    chinUp: chinUp,
    weightedPullUp: weightedPullUp,
    frontLeverRow: frontLeverRow,
    weightedHorizontalRow: weightedHorizontalRow,
    oneArmRow: oneArmRow,
    backLever: backLever,
    bicepCurl: bicepCurl,
    lateralRaise: lateralRaise,
    shrug: shrug,
    copenhagenPlank: copenhagenPlank,
    hyperextension: hyperextension,
    pikePullThrough: pikePullThrough,
    pancakeStretch: pancakeStretch,
    weightedHang: weightedHang,
    straddleSplits: straddleSplits,
    hangingLegRaise: hangingLegRaise,
    legCurl: legCurl,
    benchPress: benchPress,
    militaryPress: militaryPress,
    dumbbellRow: dumbbellRow,
    deadlift: deadlift,
    romanianDeadlift: romanianDeadlift
};
