// Generated by purs version 0.14.0
"use strict";
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var WorkoutBuilder_Client_Images = require("../WorkoutBuilder.Client.Images/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var toggle = function (label) {
    return function (action) {
        return function (active) {
            var toggledClass = (function () {
                if (active) {
                    return " toggle--active";
                };
                return "";
            })();
            return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("toggle" + toggledClass), Halogen_HTML_Events.onClick(function (v) {
                return action;
            }) ])([ Halogen_HTML_Elements.img([ Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.check) ]), Halogen_HTML_Core.text(label) ]);
        };
    };
};
module.exports = {
    toggle: toggle
};
