// Generated by purs version 0.14.0
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var WorkoutBuilder_Client_Images = require("../WorkoutBuilder.Client.Images/index.js");
var WorkoutBuilder_Client_State = require("../WorkoutBuilder.Client.State/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var WorkoutBuilder_Exercises = require("../WorkoutBuilder.Exercises/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var addExerciseRow = function (group) {
    return function (v) {
        return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("add-exercise-row"), Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.AddExercise({
            group: group,
            exercise: v
        }))) ])([ Halogen_HTML_Core.text(v.name) ]);
    };
};
var addExerciseModal = function (group) {
    var newExercise = WorkoutBuilder_Types.exercise("New exercise")(WorkoutBuilder_Types.Compound.value);
    var addNewExercise = Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("add-exercise-row"), Halogen_HTML_Events.onClick(Data_Function["const"](new WorkoutBuilder_Client_State.AddExercise({
        group: group,
        exercise: newExercise
    }))) ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button") ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-add"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.add) ]), Halogen_HTML_Core.text("New Exercise") ]) ]);
    return Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-content") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-title-bar") ])([ Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-title") ])([ Halogen_HTML_Core.text("Add exercise to group '" + (group.name + "'")) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("icon-button"), Halogen_HTML_Events.onClick(Data_Function["const"](WorkoutBuilder_Client_State.CloseModal.value)) ])([ Halogen_HTML_Elements.img([ WorkoutBuilder_Client_UiPrelude.cls("btn-close"), Halogen_HTML_Properties.src(WorkoutBuilder_Client_Images.images.close) ]) ]) ]), Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("modal-body") ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ addNewExercise ])(Data_Functor.map(Data_Functor.functorArray)(addExerciseRow(group))(Data_Array.sortWith(Data_Ord.ordString)(function (v) {
        return v.name;
    })(WorkoutBuilder_Exercises.allExercises)))) ]);
};
module.exports = {
    addExerciseModal: addExerciseModal,
    addExerciseRow: addExerciseRow
};
