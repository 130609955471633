// Generated by purs version 0.14.0
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var WorkoutBuilder_Muscles = require("../WorkoutBuilder.Muscles/index.js");
var WorkoutBuilder_OwnPrelude = require("../WorkoutBuilder.OwnPrelude/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var repSchemeVolume = function (v) {
    return {
        sets: Data_Int.toNumber(v.sets),
        reps: {
            min: Data_Int.toNumber(v.sets * v.reps.min | 0),
            max: Data_Int.toNumber(v.sets * v.reps.max | 0)
        }
    };
};
var muscleVolumeByPushPull = function (muscles) {
    var inSet = function (toMatch) {
        return Data_Map_Internal.filterKeys(Data_Ord.ordString)(function (muscle) {
            return Data_Set.member(Data_Ord.ordString)(muscle)(toMatch);
        })(muscles);
    };
    return {
        push: inSet(WorkoutBuilder_Muscles.byPushPull.push),
        pull: inSet(WorkoutBuilder_Muscles.byPushPull.pull),
        legs: inSet(WorkoutBuilder_Muscles.byPushPull.legs),
        core: inSet(WorkoutBuilder_Muscles.byPushPull.core)
    };
};
var muscleExercisesUnion = function (exs1) {
    return function (exs2) {
        return {
            targetCompound: Data_Set.union(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "repScheme";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                return "group";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "exercise";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "scheme";
            }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                return "name";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "muscles";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "target";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "synergists";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "stabilizers";
            }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                return "category";
            }))(WorkoutBuilder_Types.ordExerciseCategory)))))(exs1.targetCompound)(exs2.targetCompound),
            targetIsolation: Data_Set.union(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "repScheme";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                return "group";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "exercise";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "scheme";
            }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                return "name";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "muscles";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "target";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "synergists";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "stabilizers";
            }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                return "category";
            }))(WorkoutBuilder_Types.ordExerciseCategory)))))(exs1.targetIsolation)(exs2.targetIsolation),
            synergists: Data_Set.union(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "repScheme";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                return "group";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "exercise";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "scheme";
            }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                return "name";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "muscles";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "target";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "synergists";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "stabilizers";
            }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                return "category";
            }))(WorkoutBuilder_Types.ordExerciseCategory)))))(exs1.synergists)(exs2.synergists),
            stabilizers: Data_Set.union(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "repScheme";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                return "group";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "exercise";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "scheme";
            }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "sets";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "reps";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "min";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "max";
            }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                return "name";
            }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                return "muscles";
            }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "target";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "synergists";
            }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                return "stabilizers";
            }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                return "category";
            }))(WorkoutBuilder_Types.ordExerciseCategory)))))(exs1.stabilizers)(exs2.stabilizers)
        };
    };
};
var minutesPerSet = 3.5;
var volumeWorkoutTime = function (v) {
    return v.sets * minutesPerSet;
};
var foldDayField = function (dictMonoid) {
    return function (fn) {
        return function (v) {
            if (v instanceof WorkoutBuilder_Types.RestDay) {
                return Data_Monoid.mempty(dictMonoid);
            };
            if (v instanceof WorkoutBuilder_Types.WorkoutDay) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(dictMonoid)(Data_Functor.map(Data_Functor.functorArray)(fn)(v.value0.exercises));
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Analysis (line 175, column 1 - line 175, column 77): " + [ fn.constructor.name, v.constructor.name ]);
        };
    };
};
var sumDayIf = function (pred) {
    return function (day) {
        var unwrapIt = function (v) {
            return {
                sets: Data_Newtype.unwrap()(v.sets),
                reps: {
                    min: Data_Newtype.unwrap()(v.reps.min),
                    max: Data_Newtype.unwrap()(v.reps.max)
                }
            };
        };
        var foldIt = function (ex) {
            if (!pred(ex)) {
                return {
                    sets: 0.0,
                    reps: {
                        min: 0.0,
                        max: 0.0
                    }
                };
            };
            return {
                sets: Data_Int.toNumber(ex.repScheme.sets),
                reps: {
                    min: Data_Int.toNumber(ex.repScheme.reps.min * ex.repScheme.sets | 0),
                    max: Data_Int.toNumber(ex.repScheme.reps.max * ex.repScheme.sets | 0)
                }
            };
        };
        return unwrapIt(foldDayField(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil))))()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil))))(foldIt)(day));
    };
};
var sumDayVolume_ = function (day) {
    var unwrapIt = function (v) {
        return {
            sets: Data_Newtype.unwrap()(v.sets),
            reps: {
                min: Data_Newtype.unwrap()(v.reps.min),
                max: Data_Newtype.unwrap()(v.reps.max)
            }
        };
    };
    var foldIt = function (ex) {
        return {
            sets: Data_Int.toNumber(ex.repScheme.sets),
            reps: {
                min: Data_Int.toNumber(ex.repScheme.reps.min),
                max: Data_Int.toNumber(ex.repScheme.reps.max)
            }
        };
    };
    return unwrapIt(foldDayField(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil))))()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil))))(foldIt)(day));
};
var sumRepsMax = function (day) {
    return Data_Newtype.unwrap()(foldDayField(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))(function (v) {
        return Data_Int.toNumber(v.repScheme.sets * v.repScheme.reps.max | 0);
    })(day));
};
var sumRepsMin = function (day) {
    return Data_Newtype.unwrap()(foldDayField(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))(function (v) {
        return Data_Int.toNumber(v.repScheme.sets * v.repScheme.reps.min | 0);
    })(day));
};
var sumSets = function (day) {
    return Data_Newtype.unwrap()(foldDayField(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))(function ($78) {
        return Data_Monoid_Additive.Additive(Data_Int.toNumber((function (v) {
            return v.repScheme.sets;
        })($78)));
    })(day));
};
var sumDayVolume = function (day) {
    return {
        sets: sumSets(day),
        reps: {
            min: sumRepsMin(day),
            max: sumRepsMax(day)
        }
    };
};
var workoutTimeInMinutes = function (plan) {
    return sumSets(plan) * minutesPerSet;
};
var emptyVol = {
    sets: 0.0,
    reps: {
        min: 0.0,
        max: 0.0
    }
};
var emptyMuscleExercises = {
    targetCompound: Data_Set.empty,
    targetIsolation: Data_Set.empty,
    synergists: Data_Set.empty,
    stabilizers: Data_Set.empty
};
var musclesForExercise = function (v) {
    var mkExs = function (v1) {
        return function (v2) {
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.TargetMuscle) {
                return {
                    targetCompound: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ]),
                    targetIsolation: emptyMuscleExercises.targetIsolation,
                    synergists: emptyMuscleExercises.synergists,
                    stabilizers: emptyMuscleExercises.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.SynergistMuscle) {
                return {
                    targetCompound: emptyMuscleExercises.targetCompound,
                    targetIsolation: emptyMuscleExercises.targetIsolation,
                    synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ]),
                    stabilizers: emptyMuscleExercises.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.StabilizerMuscle) {
                return {
                    targetCompound: emptyMuscleExercises.targetCompound,
                    targetIsolation: emptyMuscleExercises.targetIsolation,
                    synergists: emptyMuscleExercises.synergists,
                    stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ])
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.TargetMuscle) {
                return {
                    targetCompound: emptyMuscleExercises.targetCompound,
                    targetIsolation: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ]),
                    synergists: emptyMuscleExercises.synergists,
                    stabilizers: emptyMuscleExercises.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.SynergistMuscle) {
                return {
                    targetCompound: emptyMuscleExercises.targetCompound,
                    targetIsolation: emptyMuscleExercises.targetIsolation,
                    synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ]),
                    stabilizers: emptyMuscleExercises.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.StabilizerMuscle) {
                return {
                    targetCompound: emptyMuscleExercises.targetCompound,
                    targetIsolation: emptyMuscleExercises.targetIsolation,
                    synergists: emptyMuscleExercises.synergists,
                    stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "repScheme";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
                        return "group";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "exercise";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "scheme";
                    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "sets";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "reps";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "min";
                    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                        return "max";
                    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                        return "muscles";
                    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                        return "target";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "synergists";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                        return "stabilizers";
                    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                        return "category";
                    }))(WorkoutBuilder_Types.ordExerciseCategory)))))([ v ])
                };
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Analysis (line 226, column 5 - line 226, column 63): " + [ v1.constructor.name, v2.constructor.name ]);
        };
    };
    var getMuscles = function (ms) {
        return function (role) {
            return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(muscleExercisesUnion))(Data_Map_Internal.empty)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
                return Data_Map_Internal.singleton(m)(mkExs(v.exercise.category)(role));
            })(Data_Array.fromFoldable(Data_Set.foldableSet)(ms)));
        };
    };
    return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(muscleExercisesUnion))(Data_Map_Internal.empty)([ getMuscles(v.exercise.muscles.target)(WorkoutBuilder_Types.TargetMuscle.value), getMuscles(v.exercise.muscles.synergists)(WorkoutBuilder_Types.SynergistMuscle.value), getMuscles(v.exercise.muscles.stabilizers)(WorkoutBuilder_Types.StabilizerMuscle.value) ]);
};
var emptyMuscleExerciseVol = {
    targetCompound: emptyVol,
    targetIsolation: emptyVol,
    synergists: emptyVol,
    stabilizers: emptyVol
};
var emptyMuscleMap = Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
    return new Data_Tuple.Tuple(m, emptyMuscleExerciseVol);
})(WorkoutBuilder_Muscles.allMuscles));
var dayGroups = foldDayField(Data_Set.monoidSet(Data_Ord.ordString))(function ($79) {
    return Data_Set.singleton((function (v) {
        return v.group;
    })($79));
});
var dayExercises = function (v) {
    if (v instanceof WorkoutBuilder_Types.RestDay) {
        return Data_Set.empty;
    };
    if (v instanceof WorkoutBuilder_Types.WorkoutDay) {
        return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "repScheme";
        }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
            return "group";
        }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
            return "exercise";
        }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "scheme";
        }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
            return "muscles";
        }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
            return "target";
        }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
            return "synergists";
        }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
            return "stabilizers";
        }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
            return "category";
        }))(WorkoutBuilder_Types.ordExerciseCategory)))))(v.value0.exercises);
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Analysis (line 248, column 1 - line 248, column 47): " + [ v.constructor.name ]);
};
var exercisesByMuscle = function (v) {
    var allExercises = Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Set.union(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "repScheme";
    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Data_Ord.ordInt))))))()(new Data_Symbol.IsSymbol(function () {
        return "group";
    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
        return "exercise";
    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "scheme";
    }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
        return "muscles";
    }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
        return "target";
    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
        return "synergists";
    }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
        return "stabilizers";
    }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
        return "category";
    }))(WorkoutBuilder_Types.ordExerciseCategory))))))(Data_Set.empty)(Data_Functor.map(Data_Functor.functorArray)(dayExercises)(v.days));
    return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(muscleExercisesUnion))(Data_Map_Internal.empty)(Data_Functor.map(Data_Functor.functorArray)(musclesForExercise)(Data_Array.fromFoldable(Data_Set.foldableSet)(allExercises)));
};
var addVolume = function (volA) {
    return function (volB) {
        return {
            sets: volA.sets + volB.sets,
            reps: {
                min: volA.reps.min + volB.reps.min,
                max: volA.reps.max + volB.reps.max
            }
        };
    };
};
var addMuscleExerciseVolume = function (volA) {
    return function (volB) {
        return {
            targetCompound: addVolume(volA.targetCompound)(volB.targetCompound),
            targetIsolation: addVolume(volA.targetIsolation)(volB.targetIsolation),
            synergists: addVolume(volA.synergists)(volB.synergists),
            stabilizers: addVolume(volA.stabilizers)(volB.stabilizers)
        };
    };
};
var exerciseMuscleVolume = function (v) {
    var vol = repSchemeVolume(v.repScheme);
    var mkVol = function (v1) {
        return function (v2) {
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.TargetMuscle) {
                return {
                    targetCompound: vol,
                    targetIsolation: emptyMuscleExerciseVol.targetIsolation,
                    synergists: emptyMuscleExerciseVol.synergists,
                    stabilizers: emptyMuscleExerciseVol.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.SynergistMuscle) {
                return {
                    targetCompound: emptyMuscleExerciseVol.targetCompound,
                    targetIsolation: emptyMuscleExerciseVol.targetIsolation,
                    synergists: vol,
                    stabilizers: emptyMuscleExerciseVol.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Compound && v2 instanceof WorkoutBuilder_Types.StabilizerMuscle) {
                return {
                    targetCompound: emptyMuscleExerciseVol.targetCompound,
                    targetIsolation: emptyMuscleExerciseVol.targetIsolation,
                    synergists: emptyMuscleExerciseVol.synergists,
                    stabilizers: vol
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.TargetMuscle) {
                return {
                    targetCompound: emptyMuscleExerciseVol.targetCompound,
                    targetIsolation: vol,
                    synergists: emptyMuscleExerciseVol.synergists,
                    stabilizers: emptyMuscleExerciseVol.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.SynergistMuscle) {
                return {
                    targetCompound: emptyMuscleExerciseVol.targetCompound,
                    targetIsolation: emptyMuscleExerciseVol.targetIsolation,
                    synergists: vol,
                    stabilizers: emptyMuscleExerciseVol.stabilizers
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.Isolation && v2 instanceof WorkoutBuilder_Types.StabilizerMuscle) {
                return {
                    targetCompound: emptyMuscleExerciseVol.targetCompound,
                    targetIsolation: emptyMuscleExerciseVol.targetIsolation,
                    synergists: emptyMuscleExerciseVol.synergists,
                    stabilizers: vol
                };
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Analysis (line 99, column 5 - line 99, column 68): " + [ v1.constructor.name, v2.constructor.name ]);
        };
    };
    var getVolume = function (ms) {
        return function (role) {
            return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(addMuscleExerciseVolume))(Data_Map_Internal.empty)(Data_Functor.map(Data_Functor.functorArray)(function (m) {
                return Data_Map_Internal.singleton(m)(mkVol(v.exercise.category)(role));
            })(Data_Array.fromFoldable(Data_Set.foldableSet)(ms)));
        };
    };
    return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(addMuscleExerciseVolume))(Data_Map_Internal.empty)([ getVolume(v.exercise.muscles.target)(WorkoutBuilder_Types.TargetMuscle.value), getVolume(v.exercise.muscles.synergists)(WorkoutBuilder_Types.SynergistMuscle.value), getVolume(v.exercise.muscles.stabilizers)(WorkoutBuilder_Types.StabilizerMuscle.value) ]);
};
var dayMuscleVolume = function (v) {
    if (v instanceof WorkoutBuilder_Types.RestDay) {
        return Data_Map_Internal.empty;
    };
    if (v instanceof WorkoutBuilder_Types.WorkoutDay) {
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(addMuscleExerciseVolume))(Data_Map_Internal.empty)(Data_Functor.map(Data_Functor.functorArray)(exerciseMuscleVolume)(v.value0.exercises));
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Analysis (line 76, column 1 - line 76, column 62): " + [ v.constructor.name ]);
};
var muscleVolume = function (v) {
    return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.unionWith(Data_Ord.ordString)(addMuscleExerciseVolume))(emptyMuscleMap)(Data_Functor.map(Data_Functor.functorArray)(dayMuscleVolume)(v.days));
};
var programVolume = function (program) {
    var toWeeklyTotal = function (val) {
        return (val * 7.0) / Data_Int.toNumber(Data_Array.length(program.days));
    };
    var toWeeklyVol = function (vol) {
        return {
            sets: toWeeklyTotal(vol.sets),
            reps: {
                min: toWeeklyTotal(vol.reps.min),
                max: toWeeklyTotal(vol.reps.max)
            }
        };
    };
    var sumGroup = function (group) {
        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringRecord()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
            return "reps";
        }))()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
            return "sets";
        }))()(Data_Semiring.semiringRecordNil)(Data_Semiring.semiringNumber))(Data_Semiring.semiringRecord()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
            return "max";
        }))()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
            return "min";
        }))()(Data_Semiring.semiringRecordNil)(Data_Semiring.semiringNumber))(Data_Semiring.semiringNumber)))))(Data_Functor.map(Data_Functor.functorArray)(sumDayIf(function (ex) {
            return ex.group === group;
        }))(program.days));
    };
    var muscleVolToWeeklyVol = function (v) {
        return {
            targetCompound: toWeeklyVol(v.targetCompound),
            targetIsolation: toWeeklyVol(v.targetIsolation),
            synergists: toWeeklyVol(v.synergists),
            stabilizers: toWeeklyVol(v.stabilizers)
        };
    };
    var fullGroups = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Set.monoidSet(Data_Ord.ordString))(dayGroups)(program.days);
    var dayVol = Data_Functor.map(Data_Functor.functorArray)(sumDayVolume)(program.days);
    var total = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringRecord()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))()(Data_Semiring.semiringRecordNil)(Data_Semiring.semiringNumber))(Data_Semiring.semiringRecord()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))()(Data_Semiring.semiringRecordCons(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))()(Data_Semiring.semiringRecordNil)(Data_Semiring.semiringNumber))(Data_Semiring.semiringNumber)))))(dayVol);
    return {
        total: toWeeklyVol(total),
        groups: Data_Functor.map(Data_Functor.functorArray)(function (group) {
            return {
                group: group,
                volume: toWeeklyVol(sumGroup(group))
            };
        })(Data_Array.fromFoldable(Data_Set.foldableSet)(fullGroups)),
        day: dayVol,
        muscles: Data_Functor.map(Data_Map_Internal.functorMap)(muscleVolToWeeklyVol)(muscleVolume(program))
    };
};
module.exports = {
    minutesPerSet: minutesPerSet,
    programVolume: programVolume,
    muscleVolume: muscleVolume,
    dayMuscleVolume: dayMuscleVolume,
    exerciseMuscleVolume: exerciseMuscleVolume,
    repSchemeVolume: repSchemeVolume,
    emptyMuscleMap: emptyMuscleMap,
    emptyMuscleExerciseVol: emptyMuscleExerciseVol,
    emptyVol: emptyVol,
    addMuscleExerciseVolume: addMuscleExerciseVolume,
    addVolume: addVolume,
    sumDayVolume: sumDayVolume,
    sumDayVolume_: sumDayVolume_,
    sumDayIf: sumDayIf,
    sumSets: sumSets,
    sumRepsMin: sumRepsMin,
    sumRepsMax: sumRepsMax,
    dayGroups: dayGroups,
    foldDayField: foldDayField,
    workoutTimeInMinutes: workoutTimeInMinutes,
    volumeWorkoutTime: volumeWorkoutTime,
    muscleVolumeByPushPull: muscleVolumeByPushPull,
    exercisesByMuscle: exercisesByMuscle,
    musclesForExercise: musclesForExercise,
    emptyMuscleExercises: emptyMuscleExercises,
    muscleExercisesUnion: muscleExercisesUnion,
    dayExercises: dayExercises
};
