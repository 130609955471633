// Generated by purs version 0.14.0
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var WorkoutBuilder_Exercises = require("../WorkoutBuilder.Exercises/index.js");
var WorkoutBuilder_OwnPrelude = require("../WorkoutBuilder.OwnPrelude/index.js");
var WorkoutBuilder_Programming = require("../WorkoutBuilder.Programming/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var rrParams = {
    name: "r/bodyweightfitness Recommended Routine",
    days: [ new WorkoutBuilder_Types.ScheduleWorkout(WorkoutBuilder_Types.emptyScheduleWorkoutDay), WorkoutBuilder_Types.ScheduleRest.value, new WorkoutBuilder_Types.ScheduleWorkout(WorkoutBuilder_Types.emptyScheduleWorkoutDay), WorkoutBuilder_Types.ScheduleRest.value, new WorkoutBuilder_Types.ScheduleWorkout(WorkoutBuilder_Types.emptyScheduleWorkoutDay), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ],
    groups: [ {
        name: "push",
        exercises: [ WorkoutBuilder_Exercises.pushUp, WorkoutBuilder_Exercises.weightedDip ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "pull",
        exercises: [ WorkoutBuilder_Exercises.pullUp, WorkoutBuilder_Exercises.frontLeverRow ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "core",
        exercises: [ WorkoutBuilder_Exercises.hangingLegRaise, WorkoutBuilder_Exercises.copenhagenPlank, WorkoutBuilder_Exercises.hyperextension ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 27
    }, {
        name: "legs",
        exercises: [ WorkoutBuilder_Exercises.shrimpSquat, WorkoutBuilder_Exercises.nordicCurl ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    } ],
    defaults: WorkoutBuilder_Programming.defaults.defaults
};
var rrSplit = {
    name: "r/bodyweightfitness Recommended Routine Split",
    days: [ WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "pull", "legs" ])), WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "push", "core" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "pull", "legs" ])), WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "push", "core" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ],
    groups: rrParams.groups,
    defaults: rrParams.defaults
};
var rrNoCore = {
    name: "r/bodyweightfitness Recommended Routine - No Core",
    days: [ WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ],
    groups: [ {
        name: "push",
        exercises: [ WorkoutBuilder_Exercises.pushUp, WorkoutBuilder_Exercises.weightedDip ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "pull",
        exercises: [ WorkoutBuilder_Exercises.pullUp, WorkoutBuilder_Exercises.frontLeverRow ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "legs",
        exercises: [ WorkoutBuilder_Exercises.shrimpSquat, WorkoutBuilder_Exercises.nordicCurl ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    } ],
    defaults: WorkoutBuilder_Programming.defaults.defaults
};
var minimalist = {
    name: "Minimalist Bodyweight",
    days: [ WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([  ])), WorkoutBuilder_Types.ScheduleRest.value ],
    groups: [ {
        name: "push",
        exercises: [ WorkoutBuilder_Exercises.pushUp ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "pull",
        exercises: [ WorkoutBuilder_Exercises.pullUp ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    }, {
        name: "legs",
        exercises: [ WorkoutBuilder_Exercises.shrimpSquat ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 18
    } ],
    defaults: WorkoutBuilder_Programming.defaults.defaults
};
var fiveThreeOneSchemes = Data_Map_Internal.fromFoldable(WorkoutBuilder_Types.ordIntensity)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(WorkoutBuilder_Types.H.value, {
    sets: 5,
    reps: {
        min: 1,
        max: 5
    }
}), new Data_Tuple.Tuple(WorkoutBuilder_Types.M.value, {
    sets: 1,
    reps: {
        min: 3,
        max: 3
    }
}), new Data_Tuple.Tuple(WorkoutBuilder_Types.L.value, {
    sets: 1,
    reps: {
        min: 5,
        max: 5
    }
}), new Data_Tuple.Tuple(WorkoutBuilder_Types.VL.value, {
    sets: 1,
    reps: {
        min: 5,
        max: 5
    }
}) ]);
var fiveThreeOnePeriods = new WorkoutBuilder_Types.DailyUndulating({
    schedule: [ WorkoutBuilder_Types.L.value, WorkoutBuilder_Types.M.value, WorkoutBuilder_Types.H.value ]
});
var fiveThreeOneLighter = Data_Map_Internal.fromFoldable(WorkoutBuilder_Types.ordIntensity)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(WorkoutBuilder_Types.M.value, {
    sets: 1,
    reps: {
        min: 15,
        max: 15
    }
}) ]);
var fiveThreeOneLight = Data_Map_Internal.fromFoldable(WorkoutBuilder_Types.ordIntensity)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(WorkoutBuilder_Types.M.value, {
    sets: 1,
    reps: {
        min: 10,
        max: 10
    }
}) ]);
var fiveThreeOne = {
    name: "5/3/1 Boring But Big",
    days: [ WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "press", "press (assistance)" ])), WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "deadlift", "deadlift (assistance)" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "bench", "bench (assistance)" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "squat", "squat (assistance)" ])), WorkoutBuilder_Types.ScheduleRest.value ],
    groups: [ {
        name: "squat",
        exercises: [ {
            name: WorkoutBuilder_Exercises.squat.name,
            scheme: fiveThreeOneSchemes,
            muscles: WorkoutBuilder_Exercises.squat.muscles,
            category: WorkoutBuilder_Exercises.squat.category
        } ],
        periodization: fiveThreeOnePeriods,
        targetSets: 3
    }, {
        name: "bench",
        exercises: [ {
            name: WorkoutBuilder_Exercises.benchPress.name,
            scheme: fiveThreeOneSchemes,
            muscles: WorkoutBuilder_Exercises.benchPress.muscles,
            category: WorkoutBuilder_Exercises.benchPress.category
        } ],
        periodization: fiveThreeOnePeriods,
        targetSets: 3
    }, {
        name: "deadlift",
        exercises: [ {
            name: WorkoutBuilder_Exercises.deadlift.name,
            scheme: fiveThreeOneSchemes,
            muscles: WorkoutBuilder_Exercises.deadlift.muscles,
            category: WorkoutBuilder_Exercises.deadlift.category
        } ],
        periodization: fiveThreeOnePeriods,
        targetSets: 3
    }, {
        name: "press",
        exercises: [ {
            name: WorkoutBuilder_Exercises.militaryPress.name,
            scheme: fiveThreeOneSchemes,
            muscles: WorkoutBuilder_Exercises.militaryPress.muscles,
            category: WorkoutBuilder_Exercises.militaryPress.category
        } ],
        periodization: fiveThreeOnePeriods,
        targetSets: 3
    }, {
        name: "squat (assistance)",
        exercises: [ {
            name: WorkoutBuilder_Exercises.squat.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.squat.muscles,
            category: WorkoutBuilder_Exercises.squat.category
        }, {
            name: WorkoutBuilder_Exercises.legCurl.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.legCurl.muscles,
            category: WorkoutBuilder_Exercises.legCurl.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 10
    }, {
        name: "bench (assistance)",
        exercises: [ {
            name: WorkoutBuilder_Exercises.benchPress.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.benchPress.muscles,
            category: WorkoutBuilder_Exercises.benchPress.category
        }, {
            name: WorkoutBuilder_Exercises.dumbbellRow.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.dumbbellRow.muscles,
            category: WorkoutBuilder_Exercises.dumbbellRow.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 10
    }, {
        name: "deadlift (assistance)",
        exercises: [ {
            name: WorkoutBuilder_Exercises.deadlift.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.deadlift.muscles,
            category: WorkoutBuilder_Exercises.deadlift.category
        }, {
            name: WorkoutBuilder_Exercises.hangingLegRaise.name,
            scheme: fiveThreeOneLighter,
            muscles: WorkoutBuilder_Exercises.hangingLegRaise.muscles,
            category: WorkoutBuilder_Exercises.hangingLegRaise.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 10
    }, {
        name: "press (assistance)",
        exercises: [ {
            name: WorkoutBuilder_Exercises.militaryPress.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.militaryPress.muscles,
            category: WorkoutBuilder_Exercises.militaryPress.category
        }, {
            name: WorkoutBuilder_Exercises.chinUp.name,
            scheme: fiveThreeOneLight,
            muscles: WorkoutBuilder_Exercises.chinUp.muscles,
            category: WorkoutBuilder_Exercises.chinUp.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 10
    } ],
    defaults: WorkoutBuilder_Programming.defaults.defaults
};
var fiveByFive = Data_Map_Internal.insert(WorkoutBuilder_Types.ordIntensity)(WorkoutBuilder_Types.M.value)({
    sets: 1,
    reps: {
        min: 5,
        max: 5
    }
})(WorkoutBuilder_Types.defaultRepSchemes);
var strongLifts = {
    name: "StrongLifts",
    days: [ WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "squat", "bench", "row" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "deadlift", "press" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "squat", "bench", "row" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "deadlift", "press" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "squat", "bench", "row" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.workoutDay(WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ "deadlift", "press" ])), WorkoutBuilder_Types.ScheduleRest.value, WorkoutBuilder_Types.ScheduleRest.value ],
    groups: [ {
        name: "squat",
        exercises: [ {
            name: WorkoutBuilder_Exercises.squat.name,
            scheme: fiveByFive,
            muscles: WorkoutBuilder_Exercises.squat.muscles,
            category: WorkoutBuilder_Exercises.squat.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 8
    }, {
        name: "bench",
        exercises: [ {
            name: WorkoutBuilder_Exercises.benchPress.name,
            scheme: fiveByFive,
            muscles: WorkoutBuilder_Exercises.benchPress.muscles,
            category: WorkoutBuilder_Exercises.benchPress.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 8
    }, {
        name: "row",
        exercises: [ {
            name: WorkoutBuilder_Exercises.dumbbellRow.name,
            scheme: fiveByFive,
            muscles: WorkoutBuilder_Exercises.dumbbellRow.muscles,
            category: WorkoutBuilder_Exercises.dumbbellRow.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 8
    }, {
        name: "deadlift",
        exercises: [ {
            name: WorkoutBuilder_Exercises.deadlift.name,
            scheme: fiveByFive,
            muscles: WorkoutBuilder_Exercises.deadlift.muscles,
            category: WorkoutBuilder_Exercises.deadlift.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 1
    }, {
        name: "press",
        exercises: [ {
            name: WorkoutBuilder_Exercises.militaryPress.name,
            scheme: fiveByFive,
            muscles: WorkoutBuilder_Exercises.militaryPress.muscles,
            category: WorkoutBuilder_Exercises.militaryPress.category
        } ],
        periodization: WorkoutBuilder_Types.NoPeriodization.value,
        targetSets: 8
    } ],
    defaults: WorkoutBuilder_Programming.defaults.defaults
};
var samplePrograms = [ rrParams, rrSplit, rrNoCore, minimalist, fiveThreeOne, strongLifts ];
module.exports = {
    samplePrograms: samplePrograms,
    rrParams: rrParams,
    rrSplit: rrSplit,
    rrNoCore: rrNoCore,
    minimalist: minimalist,
    fiveThreeOne: fiveThreeOne,
    fiveThreeOnePeriods: fiveThreeOnePeriods,
    fiveThreeOneSchemes: fiveThreeOneSchemes,
    fiveThreeOneLight: fiveThreeOneLight,
    fiveThreeOneLighter: fiveThreeOneLighter,
    strongLifts: strongLifts,
    fiveByFive: fiveByFive
};
