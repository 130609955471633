// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Effect = require("../Effect/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var JSURI = require("../JSURI/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var WorkoutBuilder_Client_LZString = require("../WorkoutBuilder.Client.LZString/index.js");
var WorkoutBuilder_OwnPrelude = require("../WorkoutBuilder.OwnPrelude/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var schemeToRaw = function (scheme) {
    return Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return new Data_Tuple.Tuple(Simple_JSON.writeJSON(WorkoutBuilder_Types.writeForeignIntensity)(v.value0), v.value1);
    })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(scheme)));
};
var schemeFromRaw = function (scheme) {
    var v = Foreign_Object.toUnfoldable(Data_Unfoldable.unfoldableArray)(scheme);
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Either.applicativeEither)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return Data_Functor.map(Data_Either.functorEither)(function (intens) {
            return new Data_Tuple.Tuple(intens, v1.value1);
        })(Simple_JSON.readJSON(WorkoutBuilder_Types.readForeignIntensity)(v1.value0));
    })(v))))(function (readVals) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Map_Internal.fromFoldable(WorkoutBuilder_Types.ordIntensity)(Data_Foldable.foldableArray)(readVals));
    });
};
var periodizationToRaw = function (v) {
    if (v instanceof WorkoutBuilder_Types.NoPeriodization) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof WorkoutBuilder_Types.DailyUndulating) {
        return new Data_Maybe.Just({
            schedule: v.value0.schedule
        });
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Client.UrlCodecs (line 138, column 1 - line 138, column 56): " + [ v.constructor.name ]);
};
var periodizationFromRaw = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return WorkoutBuilder_Types.NoPeriodization.value;
    };
    if (v instanceof Data_Maybe.Just) {
        return new WorkoutBuilder_Types.DailyUndulating({
            schedule: v.value0.schedule
        });
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Client.UrlCodecs (line 142, column 1 - line 142, column 58): " + [ v.constructor.name ]);
};
var mapToObject = function (m) {
    return Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(m));
};
var exerciseMusclesToRaw = function (v) {
    return {
        target: Data_Array.fromFoldable(Data_Set.foldableSet)(v.target),
        synergists: Data_Array.fromFoldable(Data_Set.foldableSet)(v.synergists),
        stabilizers: Data_Array.fromFoldable(Data_Set.foldableSet)(v.stabilizers)
    };
};
var exerciseToRaw = function (v) {
    return {
        name: v.name,
        scheme: schemeToRaw(v.scheme),
        muscles: exerciseMusclesToRaw(v.muscles),
        category: v.category
    };
};
var groupToRaw = function (v) {
    return {
        name: v.name,
        exercises: Data_Functor.map(Data_Functor.functorArray)(exerciseToRaw)(v.exercises),
        periodization: periodizationToRaw(v.periodization),
        targetSets: v.targetSets
    };
};
var exerciseMusclesFromRaw = function (v) {
    return {
        target: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)(v.target),
        synergists: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)(v.synergists),
        stabilizers: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)(v.stabilizers)
    };
};
var exerciseFromRaw = function (v) {
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(schemeFromRaw(v.scheme))(function (scheme_) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
            name: v.name,
            scheme: scheme_,
            muscles: exerciseMusclesFromRaw(v.muscles),
            category: v.category
        });
    });
};
var groupFromRaw = function (v) {
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Traversable.sequence(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Functor.map(Data_Functor.functorArray)(exerciseFromRaw)(v.exercises)))(function (exercises_) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
            name: v.name,
            exercises: exercises_,
            periodization: periodizationFromRaw(v.periodization),
            targetSets: v.targetSets
        });
    });
};
var $$encodeURIComponent = function (val) {
    return Data_Maybe.fromJust()(JSURI["encodeURIComponent"](val));
};
var $$decodeURIComponent = function (val) {
    return Data_Maybe.fromJust()(JSURI["decodeURIComponent"](val));
};
var dayToRaw = function (v) {
    if (v instanceof WorkoutBuilder_Types.ScheduleRest) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof WorkoutBuilder_Types.ScheduleWorkout) {
        return new Data_Maybe.Just({
            groups: Data_Array.fromFoldable(Data_Set.foldableSet)(v.value0.groups)
        });
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Client.UrlCodecs (line 111, column 1 - line 111, column 40): " + [ v.constructor.name ]);
};
var programToRaw = function (v) {
    return {
        name: v.name,
        days: Data_Functor.map(Data_Functor.functorArray)(dayToRaw)(v.days),
        groups: Data_Functor.map(Data_Functor.functorArray)(groupToRaw)(v.groups),
        defaults: v.defaults
    };
};
var encodeProgramParams = (function () {
    var $74 = Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "days";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.writeForeignMaybe(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "groups";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.writeForeignString))(Simple_JSON.nilWriteForeignFields)()()()))))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "defaults";
    }))(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "minSets";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "repScheme";
    }))(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "targetSets";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()()))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "groups";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "exercises";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "category";
    }))(WorkoutBuilder_Types.writeForeignExerciseCategory)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "muscles";
    }))(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "stabilizers";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "synergists";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "target";
    }))(Simple_JSON.writeForeignArray(Simple_JSON.writeForeignString))(Simple_JSON.nilWriteForeignFields)()()())()()())()()()))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "scheme";
    }))(Simple_JSON.writeForeignObject(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())))(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "periodization";
    }))(Simple_JSON.writeForeignMaybe(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "schedule";
    }))(Simple_JSON.writeForeignArray(WorkoutBuilder_Types.writeForeignIntensity))(Simple_JSON.nilWriteForeignFields)()()())))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "targetSets";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()()));
    return function ($75) {
        return WorkoutBuilder_Client_LZString.compressToEncodedURIComponent($74(programToRaw($75)));
    };
})();
var writeParamsToUrlHash = function (params) {
    return function __do() {
        var loc = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location)();
        var encodedParams = encodeProgramParams(params);
        return Web_HTML_Location.setHash(encodedParams)(loc)();
    };
};
var dayFromRaw = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return WorkoutBuilder_Types.ScheduleRest.value;
    };
    if (v instanceof Data_Maybe.Just) {
        return new WorkoutBuilder_Types.ScheduleWorkout({
            groups: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)(v.value0.groups)
        });
    };
    throw new Error("Failed pattern match at WorkoutBuilder.Client.UrlCodecs (line 115, column 1 - line 115, column 42): " + [ v.constructor.name ]);
};
var programFromRaw = function (v) {
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Traversable.sequence(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Functor.map(Data_Functor.functorArray)(groupFromRaw)(v.groups)))(function (groups_) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
            name: v.name,
            days: Data_Functor.map(Data_Functor.functorArray)(dayFromRaw)(v.days),
            groups: groups_,
            defaults: v.defaults
        });
    });
};
var decodeProgramParams = function (str) {
    return Data_Either.either((function () {
        var $76 = Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError));
        return function ($77) {
            return Data_Either.Left.create($76($77));
        };
    })())(Data_Either.Right.create)(Control_Monad_Except.runExcept(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "days";
    }))(Simple_JSON.readArray(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "groups";
    }))(Simple_JSON.readArray(Simple_JSON.readString))(Simple_JSON.readFieldsNil)()()))))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "defaults";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "minSets";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "repScheme";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "targetSets";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "groups";
    }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "exercises";
    }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "category";
    }))(WorkoutBuilder_Types.readForeignExerciseCategory)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "muscles";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "stabilizers";
    }))(Simple_JSON.readArray(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "synergists";
    }))(Simple_JSON.readArray(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "target";
    }))(Simple_JSON.readArray(Simple_JSON.readString))(Simple_JSON.readFieldsNil)()())()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scheme";
    }))(Simple_JSON.readObject(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "reps";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "max";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "min";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "sets";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "periodization";
    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "schedule";
    }))(Simple_JSON.readArray(WorkoutBuilder_Types.readForeignIntensity))(Simple_JSON.readFieldsNil)()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "targetSets";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()()))(WorkoutBuilder_Client_LZString.decompressFromEncodedURIComponent(str))))(programFromRaw)));
};
var readParamsFromUrlHash = function __do() {
    var hash = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.hash)();
    var strippedHash = Data_Maybe.fromMaybe(hash)(Data_String_CodeUnits.stripPrefix("#")(hash));
    return decodeProgramParams(strippedHash);
};
module.exports = {
    writeParamsToUrlHash: writeParamsToUrlHash,
    readParamsFromUrlHash: readParamsFromUrlHash,
    encodeProgramParams: encodeProgramParams,
    decodeProgramParams: decodeProgramParams,
    "encodeURIComponent": $$encodeURIComponent,
    "decodeURIComponent": $$decodeURIComponent,
    programToRaw: programToRaw,
    programFromRaw: programFromRaw,
    dayToRaw: dayToRaw,
    dayFromRaw: dayFromRaw,
    groupToRaw: groupToRaw,
    groupFromRaw: groupFromRaw,
    periodizationToRaw: periodizationToRaw,
    periodizationFromRaw: periodizationFromRaw,
    exerciseToRaw: exerciseToRaw,
    exerciseFromRaw: exerciseFromRaw,
    schemeToRaw: schemeToRaw,
    schemeFromRaw: schemeFromRaw,
    mapToObject: mapToObject,
    exerciseMusclesToRaw: exerciseMusclesToRaw,
    exerciseMusclesFromRaw: exerciseMusclesFromRaw
};
