// Generated by purs version 0.14.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var programParamDefaults = {
    targetSets: 20,
    minSets: 1,
    repScheme: {
        sets: 3,
        reps: {
            min: 5,
            max: 8
        }
    }
};
var groupWithName = function (groups) {
    return function (name) {
        return Data_Array.find(function (g) {
            return g.name === name;
        })(groups);
    };
};
var defaults = {
    name: "New Program",
    days: [  ],
    groups: [  ],
    defaults: programParamDefaults
};
var dayHasGroup = function (v) {
    return function (v1) {
        if (v1 instanceof WorkoutBuilder_Types.ScheduleRest) {
            return false;
        };
        if (v1 instanceof WorkoutBuilder_Types.ScheduleWorkout) {
            return Data_Set.isEmpty(v1.value0.groups) || Data_Set.member(Data_Ord.ordString)(v.name)(v1.value0.groups);
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Programming (line 152, column 1 - line 152, column 47): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var workoutsForMuscleGroup = function (v) {
    return function (group) {
        return Data_Array.length(Data_Array.filter(dayHasGroup(group))(v.days));
    };
};
var setsPerProg = function (params) {
    return function (group) {
        var targetSets = Data_Int.toNumber(group.targetSets);
        var numWorkouts = Data_Int.toNumber(workoutsForMuscleGroup(params)(group));
        var numWeeks = Data_Int.toNumber(Data_Array.length(params.days)) / 7.0;
        var workoutsPerWeek = numWorkouts / numWeeks;
        var numExercises = Data_Int.toNumber(Data_Array.length(group.exercises));
        return Data_Int.round(targetSets / (workoutsPerWeek * numExercises));
    };
};
var allExerciseGroups = function (v) {
    return Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Set.monoidSet(Data_Ord.ordString))(function ($46) {
        return Data_Set.singleton((function (v1) {
            return v1.name;
        })($46));
    })(v.groups);
};
var adjustVolume = function (params) {
    return function (days) {
        var setExerciseSets = function (ex) {
            return function (v) {
                if (v instanceof Data_Maybe.Nothing) {
                    return {
                        exercise: ex.exercise,
                        group: ex.group,
                        repScheme: {
                            sets: Data_Ord.max(Data_Ord.ordInt)(ex.repScheme.sets)(params.defaults.minSets),
                            reps: ex.repScheme.reps
                        }
                    };
                };
                if (v instanceof Data_Maybe.Just) {
                    return {
                        exercise: ex.exercise,
                        group: ex.group,
                        repScheme: {
                            sets: Data_Ord.max(Data_Ord.ordInt)(ex.repScheme.sets)(v.value0),
                            reps: ex.repScheme.reps
                        }
                    };
                };
                throw new Error("Failed pattern match at WorkoutBuilder.Programming (line 132, column 5 - line 132, column 71): " + [ ex.constructor.name, v.constructor.name ]);
            };
        };
        var groupSetsPerProg = Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Map_Internal.union(Data_Ord.ordString))(Data_Map_Internal.empty)(Data_Functor.map(Data_Functor.functorArray)(function (g) {
            return Data_Map_Internal.singleton(g.name)(setsPerProg(params)(g));
        })(params.groups));
        var adjustExercise = function (e) {
            return setExerciseSets(e)(Data_Map_Internal.lookup(Data_Ord.ordString)(e.group)(groupSetsPerProg));
        };
        var adjustDaySets = function (v) {
            if (v instanceof WorkoutBuilder_Types.RestDay) {
                return WorkoutBuilder_Types.RestDay.value;
            };
            if (v instanceof WorkoutBuilder_Types.WorkoutDay) {
                return new WorkoutBuilder_Types.WorkoutDay({
                    exercises: Data_Functor.map(Data_Functor.functorArray)(adjustExercise)(v.value0.exercises)
                });
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Programming (line 124, column 5 - line 124, column 40): " + [ v.constructor.name ]);
        };
        return Data_Functor.map(Data_Functor.functorArray)(adjustDaySets)(days);
    };
};
var addGroupToDay = function (params) {
    return function (v) {
        return function (group) {
            var schedule = (function () {
                if (group.periodization instanceof WorkoutBuilder_Types.NoPeriodization) {
                    return Data_Maybe.Nothing.value;
                };
                if (group.periodization instanceof WorkoutBuilder_Types.DailyUndulating) {
                    return new Data_Maybe.Just(group.periodization.value0.schedule);
                };
                throw new Error("Failed pattern match at WorkoutBuilder.Programming (line 99, column 16 - line 101, column 57): " + [ group.periodization.constructor.name ]);
            })();
            var numWorkouts = Data_Maybe.fromMaybe(0)(Data_Map_Internal.lookup(Data_Ord.ordString)(group.name)(v.workoutsForGroup));
            var intensity = Data_Maybe.fromMaybe(WorkoutBuilder_Types.M.value)(Control_Bind.bind(Data_Maybe.bindMaybe)(schedule)(function (arr) {
                return Data_Array.index(arr)(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(numWorkouts)(Data_Array.length(arr)));
            }));
            var exerciseRepScheme = function (ex) {
                return Data_Maybe.fromMaybe(params.defaults.repScheme)(Data_Map_Internal.lookup(WorkoutBuilder_Types.ordIntensity)(intensity)(ex.scheme));
            };
            var groupExercises = Data_Functor.map(Data_Functor.functorArray)(function (exercise) {
                return {
                    group: group.name,
                    exercise: exercise,
                    repScheme: exerciseRepScheme(exercise)
                };
            })(group.exercises);
            return {
                exercises: Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.exercises)(groupExercises),
                workoutsForGroup: Data_Map_Internal.insert(Data_Ord.ordString)(group.name)(numWorkouts + 1 | 0)(v.workoutsForGroup)
            };
        };
    };
};
var buildDay = function (params) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof WorkoutBuilder_Types.ScheduleRest) {
                return {
                    program: {
                        days: Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.program.days)([ WorkoutBuilder_Types.RestDay.value ])
                    },
                    workoutsForGroup: v.workoutsForGroup
                };
            };
            if (v1 instanceof WorkoutBuilder_Types.ScheduleWorkout) {
                var groupsToAdd = (function () {
                    var $41 = Data_Set.isEmpty(v1.value0.groups);
                    if ($41) {
                        return allExerciseGroups(params);
                    };
                    return v1.value0.groups;
                })();
                var groupsToAdd_ = Data_Set.mapMaybe(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                    return "targetSets";
                }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                    return "periodization";
                }))(WorkoutBuilder_Types.ordPeriodization))()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                    return "exercises";
                }))(Data_Ord.ordArray(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                    return "scheme";
                }))(Data_Map_Internal.ordMap(WorkoutBuilder_Types.ordIntensity)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                    return "sets";
                }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                    return "reps";
                }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                    return "min";
                }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                    return "max";
                }))(Data_Ord.ordInt)))))))()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Ord.ordString))()(new Data_Symbol.IsSymbol(function () {
                    return "muscles";
                }))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                    return "target";
                }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                    return "synergists";
                }))(Data_Set.ordSet(Data_Ord.ordString)))()(new Data_Symbol.IsSymbol(function () {
                    return "stabilizers";
                }))(Data_Set.ordSet(Data_Ord.ordString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "category";
                }))(WorkoutBuilder_Types.ordExerciseCategory))))))(groupWithName(params.groups))(groupsToAdd);
                var resultState = Data_Foldable.foldl(Data_Set.foldableSet)(addGroupToDay(params))({
                    exercises: [  ],
                    workoutsForGroup: v.workoutsForGroup
                })(groupsToAdd_);
                return {
                    program: {
                        days: Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.program.days)([ new WorkoutBuilder_Types.WorkoutDay({
                            exercises: resultState.exercises
                        }) ])
                    },
                    workoutsForGroup: resultState.workoutsForGroup
                };
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Programming (line 57, column 1 - line 57, column 73): " + [ params.constructor.name, v.constructor.name, v1.constructor.name ]);
        };
    };
};
var buildProgram = function (params) {
    var numCycles = Data_Int.ceil((7.0 * Data_Int.toNumber(12)) / Data_Int.toNumber(Data_Array.length(params.days)));
    var fullDays = Control_Bind.join(Control_Bind.bindArray)(Data_Array.replicate(numCycles)(params.days));
    var emptyState = {
        program: {
            days: [  ]
        },
        workoutsForGroup: Data_Map_Internal.empty
    };
    var resultState = Data_Array.foldl(buildDay(params))(emptyState)(fullDays);
    return {
        days: adjustVolume(params)(resultState.program.days)
    };
};
module.exports = {
    defaults: defaults,
    programParamDefaults: programParamDefaults,
    buildProgram: buildProgram,
    buildDay: buildDay,
    groupWithName: groupWithName,
    allExerciseGroups: allExerciseGroups,
    addGroupToDay: addGroupToDay,
    adjustVolume: adjustVolume,
    setsPerProg: setsPerProg,
    workoutsForMuscleGroup: workoutsForMuscleGroup,
    dayHasGroup: dayHasGroup
};
