// Generated by purs version 0.14.0
"use strict";
var Data_Set = require("../Data.Set/index.js");
var set = function (dictFoldable) {
    return function (dictOrd) {
        return Data_Set.fromFoldable(dictFoldable)(dictOrd);
    };
};
module.exports = {
    set: set
};
