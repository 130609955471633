// Generated by purs version 0.14.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Web_Event_Event = require("../Web.Event.Event/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var WorkoutBuilder_Client_UrlCodecs = require("../WorkoutBuilder.Client.UrlCodecs/index.js");
var WorkoutBuilder_Programming = require("../WorkoutBuilder.Programming/index.js");
var WorkoutBuilder_Types = require("../WorkoutBuilder.Types/index.js");
var ModalAddingExerciseForGroup = (function () {
    function ModalAddingExerciseForGroup(value0) {
        this.value0 = value0;
    };
    ModalAddingExerciseForGroup.create = function (value0) {
        return new ModalAddingExerciseForGroup(value0);
    };
    return ModalAddingExerciseForGroup;
})();
var ModalEditingExercise = (function () {
    function ModalEditingExercise(value0) {
        this.value0 = value0;
    };
    ModalEditingExercise.create = function (value0) {
        return new ModalEditingExercise(value0);
    };
    return ModalEditingExercise;
})();
var ModalClosed = (function () {
    function ModalClosed() {

    };
    ModalClosed.value = new ModalClosed();
    return ModalClosed;
})();
var SetParams = (function () {
    function SetParams(value0) {
        this.value0 = value0;
    };
    SetParams.create = function (value0) {
        return new SetParams(value0);
    };
    return SetParams;
})();
var SetTargetSets = (function () {
    function SetTargetSets(value0) {
        this.value0 = value0;
    };
    SetTargetSets.create = function (value0) {
        return new SetTargetSets(value0);
    };
    return SetTargetSets;
})();
var SetGroupName = (function () {
    function SetGroupName(value0) {
        this.value0 = value0;
    };
    SetGroupName.create = function (value0) {
        return new SetGroupName(value0);
    };
    return SetGroupName;
})();
var SetDupSchedule = (function () {
    function SetDupSchedule(value0) {
        this.value0 = value0;
    };
    SetDupSchedule.create = function (value0) {
        return new SetDupSchedule(value0);
    };
    return SetDupSchedule;
})();
var TogglePeriodization = (function () {
    function TogglePeriodization(value0) {
        this.value0 = value0;
    };
    TogglePeriodization.create = function (value0) {
        return new TogglePeriodization(value0);
    };
    return TogglePeriodization;
})();
var AddDupDay = (function () {
    function AddDupDay(value0) {
        this.value0 = value0;
    };
    AddDupDay.create = function (value0) {
        return new AddDupDay(value0);
    };
    return AddDupDay;
})();
var SetDupDay = (function () {
    function SetDupDay(value0) {
        this.value0 = value0;
    };
    SetDupDay.create = function (value0) {
        return new SetDupDay(value0);
    };
    return SetDupDay;
})();
var RemoveDupDay = (function () {
    function RemoveDupDay(value0) {
        this.value0 = value0;
    };
    RemoveDupDay.create = function (value0) {
        return new RemoveDupDay(value0);
    };
    return RemoveDupDay;
})();
var SetDayGroups = (function () {
    function SetDayGroups(value0) {
        this.value0 = value0;
    };
    SetDayGroups.create = function (value0) {
        return new SetDayGroups(value0);
    };
    return SetDayGroups;
})();
var AddScheduleDay = (function () {
    function AddScheduleDay() {

    };
    AddScheduleDay.value = new AddScheduleDay();
    return AddScheduleDay;
})();
var AddGroup = (function () {
    function AddGroup() {

    };
    AddGroup.value = new AddGroup();
    return AddGroup;
})();
var RemoveGroup = (function () {
    function RemoveGroup(value0) {
        this.value0 = value0;
    };
    RemoveGroup.create = function (value0) {
        return new RemoveGroup(value0);
    };
    return RemoveGroup;
})();
var RemoveScheduleDay = (function () {
    function RemoveScheduleDay(value0) {
        this.value0 = value0;
    };
    RemoveScheduleDay.create = function (value0) {
        return new RemoveScheduleDay(value0);
    };
    return RemoveScheduleDay;
})();
var SetScheduleDays = (function () {
    function SetScheduleDays(value0) {
        this.value0 = value0;
    };
    SetScheduleDays.create = function (value0) {
        return new SetScheduleDays(value0);
    };
    return SetScheduleDays;
})();
var StopPropagation = (function () {
    function StopPropagation(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    StopPropagation.create = function (value0) {
        return function (value1) {
            return new StopPropagation(value0, value1);
        };
    };
    return StopPropagation;
})();
var ShowAddExerciseModal = (function () {
    function ShowAddExerciseModal(value0) {
        this.value0 = value0;
    };
    ShowAddExerciseModal.create = function (value0) {
        return new ShowAddExerciseModal(value0);
    };
    return ShowAddExerciseModal;
})();
var CloseModal = (function () {
    function CloseModal() {

    };
    CloseModal.value = new CloseModal();
    return CloseModal;
})();
var AddExercise = (function () {
    function AddExercise(value0) {
        this.value0 = value0;
    };
    AddExercise.create = function (value0) {
        return new AddExercise(value0);
    };
    return AddExercise;
})();
var RemoveExercise = (function () {
    function RemoveExercise(value0) {
        this.value0 = value0;
    };
    RemoveExercise.create = function (value0) {
        return new RemoveExercise(value0);
    };
    return RemoveExercise;
})();
var SetInfoBarIsOpen = (function () {
    function SetInfoBarIsOpen(value0) {
        this.value0 = value0;
    };
    SetInfoBarIsOpen.create = function (value0) {
        return new SetInfoBarIsOpen(value0);
    };
    return SetInfoBarIsOpen;
})();
var ShowExerciseInfo = (function () {
    function ShowExerciseInfo(value0) {
        this.value0 = value0;
    };
    ShowExerciseInfo.create = function (value0) {
        return new ShowExerciseInfo(value0);
    };
    return ShowExerciseInfo;
})();
var EditExerciseSetMuscleRole = (function () {
    function EditExerciseSetMuscleRole(value0) {
        this.value0 = value0;
    };
    EditExerciseSetMuscleRole.create = function (value0) {
        return new EditExerciseSetMuscleRole(value0);
    };
    return EditExerciseSetMuscleRole;
})();
var EditExerciseSetCategory = (function () {
    function EditExerciseSetCategory(value0) {
        this.value0 = value0;
    };
    EditExerciseSetCategory.create = function (value0) {
        return new EditExerciseSetCategory(value0);
    };
    return EditExerciseSetCategory;
})();
var EditExerciseSetName = (function () {
    function EditExerciseSetName(value0) {
        this.value0 = value0;
    };
    EditExerciseSetName.create = function (value0) {
        return new EditExerciseSetName(value0);
    };
    return EditExerciseSetName;
})();
var EditExerciseSetRepScheme = (function () {
    function EditExerciseSetRepScheme(value0) {
        this.value0 = value0;
    };
    EditExerciseSetRepScheme.create = function (value0) {
        return new EditExerciseSetRepScheme(value0);
    };
    return EditExerciseSetRepScheme;
})();
var SaveEditedExercise = (function () {
    function SaveEditedExercise(value0) {
        this.value0 = value0;
    };
    SaveEditedExercise.create = function (value0) {
        return new SaveEditedExercise(value0);
    };
    return SaveEditedExercise;
})();
var SetProgramName = (function () {
    function SetProgramName(value0) {
        this.value0 = value0;
    };
    SetProgramName.create = function (value0) {
        return new SetProgramName(value0);
    };
    return SetProgramName;
})();
var updateScheduleDay = function (newGroups) {
    return function (v) {
        var $53 = Data_Set.isEmpty(newGroups);
        if ($53) {
            return WorkoutBuilder_Types.ScheduleRest.value;
        };
        return new WorkoutBuilder_Types.ScheduleWorkout({
            groups: newGroups
        });
    };
};
var updatePeriod_ = function (fn) {
    return function (v) {
        if (v instanceof WorkoutBuilder_Types.NoPeriodization) {
            return WorkoutBuilder_Types.NoPeriodization.value;
        };
        if (v instanceof WorkoutBuilder_Types.DailyUndulating) {
            return new WorkoutBuilder_Types.DailyUndulating({
                schedule: fn(v.value0.schedule)
            });
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 241, column 1 - line 241, column 80): " + [ fn.constructor.name, v.constructor.name ]);
    };
};
var updatePeriod = function (fn) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(updatePeriod_(fn));
};
var setMuscle = function (muscle) {
    return function (role) {
        return function (v) {
            if (role instanceof Data_Maybe.Just && role.value0 instanceof WorkoutBuilder_Types.TargetMuscle) {
                return {
                    target: Data_Set.insert(Data_Ord.ordString)(muscle)(v.target),
                    synergists: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.synergists),
                    stabilizers: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.stabilizers)
                };
            };
            if (role instanceof Data_Maybe.Just && role.value0 instanceof WorkoutBuilder_Types.SynergistMuscle) {
                return {
                    target: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.target),
                    synergists: Data_Set.insert(Data_Ord.ordString)(muscle)(v.synergists),
                    stabilizers: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.stabilizers)
                };
            };
            if (role instanceof Data_Maybe.Just && role.value0 instanceof WorkoutBuilder_Types.StabilizerMuscle) {
                return {
                    target: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.target),
                    synergists: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.synergists),
                    stabilizers: Data_Set.insert(Data_Ord.ordString)(muscle)(v.stabilizers)
                };
            };
            if (role instanceof Data_Maybe.Nothing) {
                return {
                    target: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.target),
                    synergists: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.synergists),
                    stabilizers: Data_Set["delete"](Data_Ord.ordString)(muscle)(v.stabilizers)
                };
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 201, column 3 - line 213, column 62): " + [ role.constructor.name ]);
        };
    };
};
var setIntensity = function (ind) {
    return function (i) {
        return function (schedule) {
            var v = Data_Array.updateAt(ind)(i)(schedule);
            if (v instanceof Data_Maybe.Just) {
                return v.value0;
            };
            if (v instanceof Data_Maybe.Nothing) {
                return schedule;
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 234, column 3 - line 236, column 24): " + [ v.constructor.name ]);
        };
    };
};
var removeIntensity = function (index) {
    return function (schedule) {
        var v = Data_Array.deleteAt(index)(schedule);
        if (v instanceof Data_Maybe.Just) {
            return v.value0;
        };
        if (v instanceof Data_Maybe.Nothing) {
            return schedule;
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 228, column 3 - line 230, column 24): " + [ v.constructor.name ]);
    };
};
var readInputValue = function (dictMonadEffect) {
    return function (ref) {
        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.getRef(ref))(function (elem) {
            if (elem instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value);
            };
            if (elem instanceof Data_Maybe.Just) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Web_HTML_HTMLInputElement.value(elem.value0)))(function (val) {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(val));
                });
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 183, column 3 - line 187, column 22): " + [ elem.constructor.name ]);
        });
    };
};
var parseSchedule = function (str) {
    return Data_Maybe.Nothing.value;
};
var nameLabel = "program-name";
var modifyGroup = function (name) {
    return function (fn) {
        return function (groups) {
            return Data_Functor.map(Data_Functor.functorArray)(function (g) {
                var $74 = g.name === name;
                if ($74) {
                    return fn(g);
                };
                return g;
            })(groups);
        };
    };
};
var modifyStateGroup = function (name) {
    return function (fn) {
        return function (state) {
            return {
                workoutParams: {
                    name: state.workoutParams.name,
                    days: state.workoutParams.days,
                    groups: modifyGroup(name)(fn)(state.workoutParams.groups),
                    defaults: state.workoutParams.defaults
                },
                lastPeriodization: state.lastPeriodization,
                infoBar: state.infoBar,
                modal: state.modal
            };
        };
    };
};
var editExercise = function (dictMonadEffect) {
    return function (fn) {
        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
            if (v.modal instanceof ModalEditingExercise) {
                var newExercise = fn(v.modal.value0.exercise);
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                    var $77 = {};
                    for (var $78 in s) {
                        if ({}.hasOwnProperty.call(s, $78)) {
                            $77[$78] = s[$78];
                        };
                    };
                    $77.modal = new ModalEditingExercise({
                        group: v.modal.value0.group,
                        exercise: newExercise,
                        index: v.modal.value0.index
                    });
                    return $77;
                });
            };
            return Effect_Class_Console.log(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))("Error: attempted to edit muscle role but no exercise was being edited");
        });
    };
};
var appendIntensity = function (i) {
    return function (schedule) {
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(schedule)([ i ]);
    };
};
var handleAction_ = function (dictMonadEffect) {
    return function (action_) {
        if (action_ instanceof SetParams) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                var $86 = {};
                for (var $87 in st) {
                    if ({}.hasOwnProperty.call(st, $87)) {
                        $86[$87] = st[$87];
                    };
                };
                $86.workoutParams = action_.value0;
                return $86;
            });
        };
        if (action_ instanceof SetDupSchedule) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                var oldGroup = WorkoutBuilder_Programming.groupWithName(v.workoutParams.groups)(action_.value0.group);
                var v1 = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                    return v2.periodization;
                })(oldGroup))(function (v2) {
                    if (v2 instanceof WorkoutBuilder_Types.NoPeriodization) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v2 instanceof WorkoutBuilder_Types.DailyUndulating) {
                        return new Data_Maybe.Just(v2.value0.schedule);
                    };
                    throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 91, column 31 - line 93, column 80): " + [ v2.constructor.name ]);
                });
                var parsed = parseSchedule(action_.value0.schedule);
                var newSchedule = (function () {
                    if (parsed instanceof Data_Maybe.Just) {
                        return parsed.value0;
                    };
                    if (parsed instanceof Data_Maybe.Nothing && v1 instanceof Data_Maybe.Just) {
                        return v1.value0;
                    };
                    if (parsed instanceof Data_Maybe.Nothing && v1 instanceof Data_Maybe.Nothing) {
                        return [  ];
                    };
                    throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 95, column 25 - line 98, column 35): " + [ parsed.constructor.name, v1.constructor.name ]);
                })();
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(modifyStateGroup(action_.value0.group)(function (v2) {
                    return {
                        name: v2.name,
                        exercises: v2.exercises,
                        periodization: new WorkoutBuilder_Types.DailyUndulating({
                            schedule: newSchedule
                        }),
                        targetSets: v2.targetSets
                    };
                })))(function () {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                });
            });
        };
        if (action_ instanceof SetTargetSets) {
            var v = Data_Int.fromString(action_.value0.sets);
            if (v instanceof Data_Maybe.Just) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(modifyStateGroup(action_.value0.group)(function (v1) {
                    return {
                        name: v1.name,
                        exercises: v1.exercises,
                        periodization: v1.periodization,
                        targetSets: v.value0
                    };
                }));
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 102, column 7 - line 105, column 20): " + [ v.constructor.name ]);
        };
        if (action_ instanceof SetGroupName) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $115 = {};
                for (var $116 in s) {
                    if ({}.hasOwnProperty.call(s, $116)) {
                        $115[$116] = s[$116];
                    };
                };
                $115.workoutParams = (function () {
                    var $112 = {};
                    for (var $113 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $113)) {
                            $112[$113] = s["workoutParams"][$113];
                        };
                    };
                    $112.groups = Data_Maybe.fromMaybe(s.workoutParams.groups)(Data_Array.modifyAt(action_.value0.index)(function (v1) {
                        var $109 = {};
                        for (var $110 in v1) {
                            if ({}.hasOwnProperty.call(v1, $110)) {
                                $109[$110] = v1[$110];
                            };
                        };
                        $109.name = action_.value0.name;
                        return $109;
                    })(s.workoutParams.groups));
                    return $112;
                })();
                return $115;
            });
        };
        if (action_ instanceof TogglePeriodization) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                var currentPeriod = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                    return v1.periodization;
                })(WorkoutBuilder_Programming.groupWithName(v.workoutParams.groups)(action_.value0.group));
                var defaultPeriod = new WorkoutBuilder_Types.DailyUndulating({
                    schedule: [ WorkoutBuilder_Types.L.value, WorkoutBuilder_Types.M.value, WorkoutBuilder_Types.H.value ]
                });
                var lastPeriodOrDefault = Data_Maybe.fromMaybe(defaultPeriod)(Data_Map_Internal.lookup(Data_Ord.ordString)(action_.value0.group)(v.lastPeriodization));
                var v1 = (function () {
                    if (currentPeriod instanceof Data_Maybe.Just && currentPeriod.value0 instanceof WorkoutBuilder_Types.NoPeriodization) {
                        return {
                            newPeriod: lastPeriodOrDefault,
                            newLast: lastPeriodOrDefault
                        };
                    };
                    if (currentPeriod instanceof Data_Maybe.Just && currentPeriod.value0 instanceof WorkoutBuilder_Types.DailyUndulating) {
                        return {
                            newPeriod: WorkoutBuilder_Types.NoPeriodization.value,
                            newLast: new WorkoutBuilder_Types.DailyUndulating({
                                schedule: currentPeriod.value0.value0.schedule
                            })
                        };
                    };
                    if (currentPeriod instanceof Data_Maybe.Nothing) {
                        return {
                            newPeriod: lastPeriodOrDefault,
                            newLast: lastPeriodOrDefault
                        };
                    };
                    throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 116, column 34 - line 122, column 79): " + [ currentPeriod.constructor.name ]);
                })();
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)((function () {
                    var $247 = modifyStateGroup(action_.value0.group)(function (v2) {
                        return {
                            name: v2.name,
                            exercises: v2.exercises,
                            periodization: v1.newPeriod,
                            targetSets: v2.targetSets
                        };
                    });
                    return function ($248) {
                        return (function (st) {
                            return {
                                lastPeriodization: Data_Map_Internal.insert(Data_Ord.ordString)(action_.value0.group)(v1.newLast)(st.lastPeriodization),
                                infoBar: st.infoBar,
                                modal: st.modal,
                                workoutParams: st.workoutParams
                            };
                        })($247($248));
                    };
                })()))(function () {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                });
            });
        };
        if (action_ instanceof AddDupDay) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(modifyStateGroup(action_.value0.group)(function (g) {
                return {
                    name: g.name,
                    exercises: g.exercises,
                    periodization: updatePeriod_(appendIntensity(action_.value0.intensity))(g.periodization),
                    targetSets: g.targetSets
                };
            }));
        };
        if (action_ instanceof SetDupDay) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(modifyStateGroup(action_.value0.group)(function (g) {
                return {
                    name: g.name,
                    exercises: g.exercises,
                    periodization: updatePeriod_(setIntensity(action_.value0.index)(action_.value0.intensity))(g.periodization),
                    targetSets: g.targetSets
                };
            }));
        };
        if (action_ instanceof RemoveDupDay) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(modifyStateGroup(action_.value0.group)(function (g) {
                return {
                    name: g.name,
                    exercises: g.exercises,
                    periodization: updatePeriod_(removeIntensity(action_.value0.index))(g.periodization),
                    targetSets: g.targetSets
                };
            }));
        };
        if (action_ instanceof SetDayGroups) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $147 = {};
                for (var $148 in s) {
                    if ({}.hasOwnProperty.call(s, $148)) {
                        $147[$148] = s[$148];
                    };
                };
                $147.workoutParams = (function () {
                    var $144 = {};
                    for (var $145 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $145)) {
                            $144[$145] = s["workoutParams"][$145];
                        };
                    };
                    $144.days = Data_Maybe.fromMaybe(s.workoutParams.days)(Data_Array.modifyAt(action_.value0.index)(updateScheduleDay(action_.value0.groups))(s.workoutParams.days));
                    return $144;
                })();
                return $147;
            });
        };
        if (action_ instanceof AddScheduleDay) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $156 = {};
                for (var $157 in s) {
                    if ({}.hasOwnProperty.call(s, $157)) {
                        $156[$157] = s[$157];
                    };
                };
                $156.workoutParams = (function () {
                    var $153 = {};
                    for (var $154 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $154)) {
                            $153[$154] = s["workoutParams"][$154];
                        };
                    };
                    $153.days = Data_Semigroup.append(Data_Semigroup.semigroupArray)(s.workoutParams.days)([ WorkoutBuilder_Types.ScheduleRest.value ]);
                    return $153;
                })();
                return $156;
            });
        };
        if (action_ instanceof AddGroup) {
            var newGroup = {
                name: "New group",
                exercises: [  ],
                periodization: WorkoutBuilder_Types.NoPeriodization.value,
                targetSets: WorkoutBuilder_Programming.programParamDefaults.targetSets
            };
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $162 = {};
                for (var $163 in s) {
                    if ({}.hasOwnProperty.call(s, $163)) {
                        $162[$163] = s[$163];
                    };
                };
                $162.workoutParams = (function () {
                    var $159 = {};
                    for (var $160 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $160)) {
                            $159[$160] = s["workoutParams"][$160];
                        };
                    };
                    $159.groups = Data_Semigroup.append(Data_Semigroup.semigroupArray)(s.workoutParams.groups)([ newGroup ]);
                    return $159;
                })();
                return $162;
            });
        };
        if (action_ instanceof RemoveGroup) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $168 = {};
                for (var $169 in s) {
                    if ({}.hasOwnProperty.call(s, $169)) {
                        $168[$169] = s[$169];
                    };
                };
                $168.workoutParams = (function () {
                    var $165 = {};
                    for (var $166 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $166)) {
                            $165[$166] = s["workoutParams"][$166];
                        };
                    };
                    $165.groups = Data_Maybe.fromMaybe(s.workoutParams.groups)(Data_Array.deleteAt(action_.value0.index)(s.workoutParams.groups));
                    return $165;
                })();
                return $168;
            });
        };
        if (action_ instanceof RemoveScheduleDay) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $176 = {};
                for (var $177 in s) {
                    if ({}.hasOwnProperty.call(s, $177)) {
                        $176[$177] = s[$177];
                    };
                };
                $176.workoutParams = (function () {
                    var $173 = {};
                    for (var $174 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $174)) {
                            $173[$174] = s["workoutParams"][$174];
                        };
                    };
                    $173.days = Data_Maybe.fromMaybe(s.workoutParams.days)(Data_Array.deleteAt(action_.value0.index)(s.workoutParams.days));
                    return $173;
                })();
                return $176;
            });
        };
        if (action_ instanceof SetScheduleDays) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $184 = {};
                for (var $185 in s) {
                    if ({}.hasOwnProperty.call(s, $185)) {
                        $184[$185] = s[$185];
                    };
                };
                $184.workoutParams = (function () {
                    var $181 = {};
                    for (var $182 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $182)) {
                            $181[$182] = s["workoutParams"][$182];
                        };
                    };
                    $181.days = action_.value0.days;
                    return $181;
                })();
                return $184;
            });
        };
        if (action_ instanceof StopPropagation) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Web_Event_Event.stopPropagation(action_.value0)))(function () {
                return handleAction(dictMonadEffect)(action_.value1);
            });
        };
        if (action_ instanceof ShowAddExerciseModal) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $191 = {};
                for (var $192 in s) {
                    if ({}.hasOwnProperty.call(s, $192)) {
                        $191[$192] = s[$192];
                    };
                };
                $191.modal = new ModalAddingExerciseForGroup({
                    group: action_.value0.group
                });
                return $191;
            });
        };
        if (action_ instanceof CloseModal) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $196 = {};
                for (var $197 in s) {
                    if ({}.hasOwnProperty.call(s, $197)) {
                        $196[$197] = s[$197];
                    };
                };
                $196.modal = ModalClosed.value;
                return $196;
            });
        };
        if (action_ instanceof AddExercise) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)((function () {
                var $249 = modifyStateGroup(action_.value0.group.name)(function (g) {
                    return {
                        name: g.name,
                        exercises: Data_Array.cons(action_.value0.exercise)(g.exercises),
                        periodization: g.periodization,
                        targetSets: g.targetSets
                    };
                });
                return function ($250) {
                    return (function (s) {
                        return {
                            modal: ModalClosed.value,
                            infoBar: s.infoBar,
                            lastPeriodization: s.lastPeriodization,
                            workoutParams: s.workoutParams
                        };
                    })($249($250));
                };
            })());
        };
        if (action_ instanceof RemoveExercise) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)((function () {
                var $251 = modifyStateGroup(action_.value0.group.name)(function (g) {
                    return {
                        name: g.name,
                        exercises: Data_Maybe.fromMaybe(g.exercises)(Data_Array.deleteAt(action_.value0.index)(g.exercises)),
                        periodization: g.periodization,
                        targetSets: g.targetSets
                    };
                });
                return function ($252) {
                    return (function (s) {
                        return {
                            modal: ModalClosed.value,
                            infoBar: s.infoBar,
                            lastPeriodization: s.lastPeriodization,
                            workoutParams: s.workoutParams
                        };
                    })($251($252));
                };
            })());
        };
        if (action_ instanceof SetInfoBarIsOpen) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $208 = {};
                for (var $209 in s) {
                    if ({}.hasOwnProperty.call(s, $209)) {
                        $208[$209] = s[$209];
                    };
                };
                $208.infoBar = (function () {
                    var $205 = {};
                    for (var $206 in s.infoBar) {
                        if ({}.hasOwnProperty.call(s.infoBar, $206)) {
                            $205[$206] = s["infoBar"][$206];
                        };
                    };
                    $205.isOpen = action_.value0.isOpen;
                    return $205;
                })();
                return $208;
            });
        };
        if (action_ instanceof ShowExerciseInfo) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $213 = {};
                for (var $214 in s) {
                    if ({}.hasOwnProperty.call(s, $214)) {
                        $213[$214] = s[$214];
                    };
                };
                $213.modal = new ModalEditingExercise({
                    group: action_.value0.group,
                    exercise: action_.value0.exercise,
                    index: action_.value0.index
                });
                return $213;
            });
        };
        if (action_ instanceof EditExerciseSetMuscleRole) {
            return editExercise(dictMonadEffect)(function (e) {
                return {
                    name: e.name,
                    scheme: e.scheme,
                    muscles: setMuscle(action_.value0.muscle)(action_.value0.role)(e.muscles),
                    category: e.category
                };
            });
        };
        if (action_ instanceof EditExerciseSetCategory) {
            return editExercise(dictMonadEffect)(function (v) {
                return {
                    name: v.name,
                    scheme: v.scheme,
                    muscles: v.muscles,
                    category: action_.value0.category
                };
            });
        };
        if (action_ instanceof EditExerciseSetName) {
            return editExercise(dictMonadEffect)(function (v) {
                return {
                    name: action_.value0.name,
                    scheme: v.scheme,
                    muscles: v.muscles,
                    category: v.category
                };
            });
        };
        if (action_ instanceof EditExerciseSetRepScheme) {
            return editExercise(dictMonadEffect)(function (e) {
                return {
                    name: e.name,
                    scheme: Data_Map_Internal.insert(WorkoutBuilder_Types.ordIntensity)(action_.value0.intensity)(action_.value0.scheme)(e.scheme),
                    muscles: e.muscles,
                    category: e.category
                };
            });
        };
        if (action_ instanceof SaveEditedExercise) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)((function () {
                    var $253 = modifyStateGroup(action_.value0.group.name)(function (g) {
                        return {
                            name: g.name,
                            exercises: Data_Maybe.fromMaybe(g.exercises)(Data_Array.updateAt(action_.value0.index)(action_.value0.exercise)(g.exercises)),
                            periodization: g.periodization,
                            targetSets: g.targetSets
                        };
                    });
                    return function ($254) {
                        return (function (s) {
                            return {
                                modal: ModalClosed.value,
                                infoBar: s.infoBar,
                                lastPeriodization: s.lastPeriodization,
                                workoutParams: s.workoutParams
                            };
                        })($253($254));
                    };
                })());
            });
        };
        if (action_ instanceof SetProgramName) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                var $239 = {};
                for (var $240 in s) {
                    if ({}.hasOwnProperty.call(s, $240)) {
                        $239[$240] = s[$240];
                    };
                };
                $239.workoutParams = (function () {
                    var $236 = {};
                    for (var $237 in s.workoutParams) {
                        if ({}.hasOwnProperty.call(s.workoutParams, $237)) {
                            $236[$237] = s["workoutParams"][$237];
                        };
                    };
                    $236.name = action_.value0.value0.label;
                    return $236;
                })();
                return $239;
            });
        };
        throw new Error("Failed pattern match at WorkoutBuilder.Client.State (line 84, column 3 - line 176, column 104): " + [ action_.constructor.name ]);
    };
};
var handleAction = function (dictMonadEffect) {
    return function (action_) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(handleAction_(dictMonadEffect)(action_))(function () {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(WorkoutBuilder_Client_UrlCodecs.writeParamsToUrlHash(v.workoutParams));
            });
        });
    };
};
module.exports = {
    nameLabel: nameLabel,
    ModalAddingExerciseForGroup: ModalAddingExerciseForGroup,
    ModalEditingExercise: ModalEditingExercise,
    ModalClosed: ModalClosed,
    SetParams: SetParams,
    SetTargetSets: SetTargetSets,
    SetGroupName: SetGroupName,
    SetDupSchedule: SetDupSchedule,
    TogglePeriodization: TogglePeriodization,
    AddDupDay: AddDupDay,
    SetDupDay: SetDupDay,
    RemoveDupDay: RemoveDupDay,
    SetDayGroups: SetDayGroups,
    AddScheduleDay: AddScheduleDay,
    AddGroup: AddGroup,
    RemoveGroup: RemoveGroup,
    RemoveScheduleDay: RemoveScheduleDay,
    SetScheduleDays: SetScheduleDays,
    StopPropagation: StopPropagation,
    ShowAddExerciseModal: ShowAddExerciseModal,
    CloseModal: CloseModal,
    AddExercise: AddExercise,
    RemoveExercise: RemoveExercise,
    SetInfoBarIsOpen: SetInfoBarIsOpen,
    ShowExerciseInfo: ShowExerciseInfo,
    EditExerciseSetMuscleRole: EditExerciseSetMuscleRole,
    EditExerciseSetCategory: EditExerciseSetCategory,
    EditExerciseSetName: EditExerciseSetName,
    EditExerciseSetRepScheme: EditExerciseSetRepScheme,
    SaveEditedExercise: SaveEditedExercise,
    SetProgramName: SetProgramName,
    handleAction: handleAction,
    handleAction_: handleAction_,
    readInputValue: readInputValue,
    editExercise: editExercise,
    setMuscle: setMuscle,
    modifyStateGroup: modifyStateGroup,
    modifyGroup: modifyGroup,
    appendIntensity: appendIntensity,
    removeIntensity: removeIntensity,
    setIntensity: setIntensity,
    updatePeriod: updatePeriod,
    updatePeriod_: updatePeriod_,
    parseSchedule: parseSchedule,
    updateScheduleDay: updateScheduleDay
};
