// Generated by purs version 0.14.0
"use strict";
var $foreign = require("./foreign.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var WorkoutBuilder_Analysis = require("../WorkoutBuilder.Analysis/index.js");
var WorkoutBuilder_Client_UiPrelude = require("../WorkoutBuilder.Client.UiPrelude/index.js");
var toChartMuscleExercises = function (exs) {
    var toArray = (function () {
        var $9 = Data_Array.fromFoldable(Data_Set.foldableSet);
        var $10 = Data_Set.map(Data_Ord.ordString)(function (v) {
            return v.exercise.name;
        });
        return function ($11) {
            return $9($10($11));
        };
    })();
    return {
        targetCompound: toArray(exs.targetCompound),
        targetIsolation: toArray(exs.targetIsolation),
        synergists: toArray(exs.synergists),
        stabilizers: toArray(exs.stabilizers)
    };
};
var mapToObject = function (m) {
    var mapArr = Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(m);
    return Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(mapArr);
};
var asBarDataArray = function (muscles) {
    return Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return {
            name: v.value0,
            targetCompound: v.value1.targetCompound.sets,
            targetIsolation: v.value1.targetIsolation.sets,
            synergists: v.value1.synergists.sets,
            stabilizers: v.value1.stabilizers.sets
        };
    })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(muscles));
};
var muscleVolToChartData = function (musclesVol) {
    return (function (byPushPull) {
        return Foreign_Object.fromHomogeneous()({
            push: asBarDataArray(byPushPull.push),
            pull: asBarDataArray(byPushPull.pull),
            legs: asBarDataArray(byPushPull.legs),
            core: asBarDataArray(byPushPull.core)
        });
    })(WorkoutBuilder_Analysis.muscleVolumeByPushPull(musclesVol));
};
var mkChartData = function (v) {
    return {
        muscles: mapToObject(Data_Functor.map(Data_Map_Internal.functorMap)(toChartMuscleExercises)(WorkoutBuilder_Analysis.exercisesByMuscle(v.program))),
        barData: muscleVolToChartData(v.muscleVolume)
    };
};
var stackedBarChart = function (id) {
    return function (chartParams) {
        return $foreign.render(id)(mkChartData(chartParams))(Halogen_HTML_Elements.div([ WorkoutBuilder_Client_UiPrelude.cls("chart"), Halogen_HTML_Properties.id(id) ])([  ]));
    };
};
module.exports = {
    stackedBarChart: stackedBarChart,
    mkChartData: mkChartData,
    toChartMuscleExercises: toChartMuscleExercises,
    mapToObject: mapToObject,
    muscleVolToChartData: muscleVolToChartData,
    asBarDataArray: asBarDataArray,
    render: $foreign.render
};
