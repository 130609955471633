// Generated by purs version 0.14.0
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var WorkoutBuilder_OwnPrelude = require("../WorkoutBuilder.OwnPrelude/index.js");
var wristFlexors = "wrist flexors";
var wristExtensors = "wrist extensors";
var triceps = "triceps";
var trapsUpper = "traps (upper)";
var trapsMiddle = "traps (middle)";
var trapsLower = "traps (lower)";
var tibialisAnterior = "tibialis anterior";
var teresMinor = "teres minor";
var teresMajor = "teres major";
var tensorFasciaeLatae = "tensor fasciae latae";
var supraspinatus = "supraspinatus";
var soleus = "soleus";
var serratusAnterior = "serratus anterior";
var sartorius = "sartorius";
var rhomboids = "rhomboids";
var rectusFemoris = "rectus femoris";
var rectusAbdominus = "rectus abdominus";
var quadriceps = "quadriceps";
var quadratusLumborum = "quadratus lumborum";
var psoasMajor = "psoas major";
var popliteus = "popliteus";
var pectineus = "pectineus";
var pecsUpper = "pectoralis major (upper)";
var pecsMinor = "pectoralis minor";
var pecsLower = "pectoralis major (lower)";
var obliques = "obliques";
var levatorScapulae = "levator scapulae";
var lats = "lats";
var infraspinatus = "infraspinatus";
var iliopsoas = "iliopsoas";
var iliocastalisThoracis = "iliocastalis thoracis";
var iliocastalisLumborum = "iliocastalis lumborum";
var hipAdductors = "hip adductors";
var hamstrings = "hamstrings";
var gracilis = "gracilis";
var gluteusMinimus = "gluteus minimus";
var gluteusMedius = "gluteus medius";
var gluteusMaximus = "gluteus maximus";
var gastrocnemius = "gastrocnemius";
var erectorSpinae = "erector spinae";
var deltoidPosterior = "deltoid (posterior)";
var deltoidLateral = "deltoid (lateral)";
var deltoidAnterior = "deltoid (anterior)";
var brachioradialis = "brachioradialis";
var brachialis = "brachialis";
var biceps = "biceps";
var adductorMagnus = "adductor magnus";
var allMuscles = [ adductorMagnus, biceps, brachialis, brachioradialis, deltoidAnterior, deltoidLateral, deltoidPosterior, erectorSpinae, gastrocnemius, gluteusMaximus, gluteusMedius, gluteusMinimus, hamstrings, infraspinatus, lats, levatorScapulae, obliques, pecsLower, pecsMinor, pecsUpper, quadratusLumborum, quadriceps, rectusAbdominus, rhomboids, serratusAnterior, soleus, supraspinatus, teresMajor, teresMinor, trapsLower, trapsMiddle, trapsUpper, triceps, wristExtensors, wristFlexors ];
var byBodyPart = {
    arms: [ triceps, brachialis, brachioradialis, biceps, wristFlexors, wristExtensors ],
    chest: [ pecsUpper, pecsLower, pecsMinor, serratusAnterior ],
    back: [ levatorScapulae, trapsUpper, trapsMiddle, trapsLower, rhomboids, lats, teresMajor, teresMinor, infraspinatus, supraspinatus ],
    shoulders: [ deltoidAnterior, deltoidLateral, deltoidPosterior ],
    legs: [ hamstrings, gluteusMaximus, gluteusMedius, gluteusMinimus, quadriceps, soleus, gastrocnemius, adductorMagnus, iliopsoas ],
    core: [ rectusAbdominus, obliques, quadratusLumborum ]
};
var byPushPull = {
    push: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ triceps, pecsUpper, pecsLower, pecsMinor, deltoidAnterior, deltoidLateral, serratusAnterior ]),
    pull: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ brachialis, brachioradialis, biceps, levatorScapulae, trapsUpper, trapsMiddle, trapsLower, rhomboids, lats, deltoidPosterior, teresMajor, teresMinor, infraspinatus, supraspinatus ]),
    legs: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ hamstrings, gluteusMaximus, gluteusMedius, gluteusMinimus, quadriceps, soleus, gastrocnemius, adductorMagnus ]),
    core: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ rectusAbdominus, obliques, quadratusLumborum, erectorSpinae ])
};
var byTrainingLandmarks = {
    back: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ rhomboids, serratusAnterior, lats ]),
    abs: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ rectusAbdominus, obliques ]),
    traps: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ trapsUpper, trapsMiddle, trapsLower ]),
    triceps: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ triceps ]),
    forearms: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ wristFlexors, wristExtensors ]),
    calves: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ soleus, gastrocnemius ]),
    "front delts": WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ deltoidAnterior ]),
    glutes: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ gluteusMaximus, gluteusMedius, gluteusMinimus ]),
    chest: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ pecsUpper, pecsLower, pecsMinor ]),
    biceps: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ biceps, brachialis, brachioradialis ]),
    quads: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ quadriceps ]),
    hamstrings: WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ hamstrings ]),
    "side delts": WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ deltoidLateral ]),
    "rear delts": WorkoutBuilder_OwnPrelude.set(Data_Foldable.foldableArray)(Data_Ord.ordString)([ deltoidPosterior ]),
    other: [ erectorSpinae, levatorScapulae, adductorMagnus ]
};
var adductorLongus = "adductor longus";
var adductorBrevis = "adductor brevis";
module.exports = {
    allMuscles: allMuscles,
    byBodyPart: byBodyPart,
    byPushPull: byPushPull,
    byTrainingLandmarks: byTrainingLandmarks,
    adductorMagnus: adductorMagnus,
    biceps: biceps,
    brachialis: brachialis,
    brachioradialis: brachioradialis,
    deltoidAnterior: deltoidAnterior,
    deltoidLateral: deltoidLateral,
    deltoidPosterior: deltoidPosterior,
    erectorSpinae: erectorSpinae,
    gastrocnemius: gastrocnemius,
    gluteusMaximus: gluteusMaximus,
    gluteusMedius: gluteusMedius,
    gluteusMinimus: gluteusMinimus,
    hamstrings: hamstrings,
    infraspinatus: infraspinatus,
    lats: lats,
    levatorScapulae: levatorScapulae,
    obliques: obliques,
    pecsLower: pecsLower,
    pecsMinor: pecsMinor,
    pecsUpper: pecsUpper,
    quadratusLumborum: quadratusLumborum,
    quadriceps: quadriceps,
    rectusAbdominus: rectusAbdominus,
    rhomboids: rhomboids,
    serratusAnterior: serratusAnterior,
    soleus: soleus,
    supraspinatus: supraspinatus,
    teresMajor: teresMajor,
    teresMinor: teresMinor,
    trapsLower: trapsLower,
    trapsMiddle: trapsMiddle,
    trapsUpper: trapsUpper,
    triceps: triceps,
    wristExtensors: wristExtensors,
    wristFlexors: wristFlexors,
    iliopsoas: iliopsoas,
    tensorFasciaeLatae: tensorFasciaeLatae,
    pectineus: pectineus,
    sartorius: sartorius,
    adductorLongus: adductorLongus,
    adductorBrevis: adductorBrevis,
    rectusFemoris: rectusFemoris,
    gracilis: gracilis,
    popliteus: popliteus,
    tibialisAnterior: tibialisAnterior,
    psoasMajor: psoasMajor,
    iliocastalisLumborum: iliocastalisLumborum,
    iliocastalisThoracis: iliocastalisThoracis,
    hipAdductors: hipAdductors
};
