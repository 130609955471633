// Generated by purs version 0.14.0
"use strict";
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Show = require("../Data.Show/index.js");
var $$Math = require("../Math/index.js");
var round = function (val) {
    var $0 = $$Math.round(val) === val;
    if ($0) {
        return Data_Show.show(Data_Show.showInt)(Data_Int.round(val));
    };
    var rounded = $$Math.round(val * 10.0) / 10.0;
    var $1 = rounded === Data_Int.toNumber(Data_Int.round(val));
    if ($1) {
        return Data_Show.show(Data_Show.showInt)(Data_Int.round(val));
    };
    return Data_Show.show(Data_Show.showNumber)(rounded);
};
var minutesToHourMinutes = function (minutes) {
    var mins = Data_Show.show(Data_Show.showInt)(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(Data_Int.floor(minutes))(60)) + " min";
    var hours = Data_Show.show(Data_Show.showInt)(Data_Int.floor(minutes / 60.0)) + " hr ";
    var $2 = minutes < 60.0;
    if ($2) {
        return mins;
    };
    return hours + mins;
};
module.exports = {
    minutesToHourMinutes: minutesToHourMinutes,
    round: round
};
